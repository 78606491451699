// global styles
@import '../../../styles/index.scss';

// Set all z-indices for trafficking table blocks / elements to be negative for scroll tucking
// All other trafficking page components will default to "auto" or >0 values.

// General summary of z-index stack for the Trafficking page
//  >10: Trafficking page modals, controls, overlays, drawer
// 0-10: Trafficking table scroll shadows, headers, overlays, etc

// Table-specific z-index key/mapping
//   10: trafficking table scoll-x shadows (left and right)
//    5: headers
//    3: table editing overlay (if ever added)
//    2: cell content modals
//    1: cell content controls
//    0: default, table, display value cells

$trafficking-filter-bar-container-z-index: 20;
$trafficking-filter-menu-z-index: 15;
// to be above the table's top border, tab nav z-index must be higher
$trafficking-tab-nav-z-index: 11;
$trafficking-selected-tab-z-index: $trafficking-tab-nav-z-index + 1;

$trafficking-table-scroll-shadow-z-index: 10;
$trafficking-table-header-z-index: 20;
$trafficking-table-editable-cell-modal-z-index: 6;
$trafficking-table-not-editable-cell-z-index: 3;
$trafficking-dropdown-z-index: 6;
$trafficking-active-dropdown-z-index: 20;

