@import "../../../styles/index.scss";

$sticky-filter-drop-shadow: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));

.sequence-table {
  background-color: $white-background;
  border-collapse: separate;
  border-spacing: 0;
  border: none;
  counter-reset: row-number;
  flex-direction: column;
  position: relative;
  text-align: left;

  &__title {
    color: #0b0c0f;
    font-size: grid-px(2.75);
    margin-bottom: grid-px(2.75);
    margin-top: 0;
  }

  &__icon-drag,
  .icon-drag {
    display: inline;
    height: grid-px(2);
    margin: (grid-px(0.5)) 0 0 !important;

    path {
      fill: $color-gray-3;
    }
  }

  &__sequence-table-scroll-div {
    overflow: auto;
  }

  &__sequence-table-bar {
    align-items: center;
    background-color: $color-solid-black-8;
    color: $color-black-1;
    display: flex;
    flex-shrink: 0;
    font-size: $font-size-standard;
    min-height: grid-px(6);
    justify-content: space-between;
    padding: 0 grid-px(2);

    &--error {
      border: grid-px(0.25) solid $color-red-base;;
    }

    .link-disabled {
      border: grid-px(0.125) solid #b9bfcb !important;
      border-radius: grid-px(0);
      cursor: not-allowed;
      pointer-events: all;
      font-size: grid-px(1.5);
      padding: grid-px(0.5) grid-px(3);

      &:hover {
        border-color: $color-faux-black;
      }
    }

    &__right-children > div > span > span {
      top: -grid-px(0.25);
    }

    &__right-children > div > span > svg {
      width: grid-px(1.625);
    }

    a {
      @include keyboard-focus-only;
      border: none;
      margin: 0;
      padding: 0;
    }

    &__left-children,
    &__right-children {
      align-items: center;
      display: flex;
    }
  }

  .controlled-checkbox {
    align-items: center;
    display: flex;
    justify-content: center;
    width: grid-px(5);

    input {
      display: inline-block;
      margin: 0;
    }
  }

  table {
    border-collapse: collapse;
    border: none;
    width: 100%;

    thead {
      background-color: #dde0e6;

      tr {
        background: $color-white;
        border-bottom: grid-px(0.125) solid #f0f1f5;
        position: relative;
      }

      th {
        background: $color-white;
        border: 0;
        color: $color-gray-1;
        font-size: $font-size-standard;
        font-weight: 500;
        line-height: 2.5;
        outline: none;
        padding: grid-px() grid-px(2) 0;
        position: sticky;
        text-align: left;
        top: 0;
        white-space: nowrap;

        .controlled-checkbox > input {
          margin: 0 0;
        }

        &:last-child {
          width: 100%;
        }
      }
    }

    th:first-of-type > :not(input[type="checkbox"]) {
      display: none;
    }

    tbody {
      > div > tr > td {
        .controlled-checkbox__label-content {
          display: none !important;
        }
      }

      [data-rbd-drag-handle-context-id="0"] {
        padding-top: grid-px(1.25);
      }

      tr {
        border-bottom: grid-px(0.125) solid #f0f1f5;

        .td__off-hover {
          display: block;
        }

        .td__on-hover {
          display: none;
        }

        .controlled-checkbox {
          > input {
            display: none;
          }
        }

        .controlled-checkbox--is-checked {
          input {
            display: inline-block !important;
            margin: 0 0;
          }

          .controlled-checkbox__label-content {
            display: none !important;
          }

          tr {
            background-color: $color-blue-6;
          }
        }

        .controlled-checkbox &:first-child td[role="cell"] {
          border-top: none;
        }

        &:last-child td[role="cell"] {
          border-bottom: none;
        }

        &:hover,
        &:focus-within {
          background: $color-gray-6;

          .controlled-checkbox {
            input {
              display: inline-block;
              outline: none;
              margin: 0 0;
            }

            &__label-content {
              display: none;
            }
          }

          .td__off-hover {
            display: none;
          }

          .td__on-hover {
            display: block;
          }

          .editable-cell--base .TrekDropDown-root .TrekDropDown-formControl > svg {
            display: block;
          }

          .editable-cell--base .editable-cell__icon--base {
            display: block;
            @include filter-gray-2;
          }

          .editable-cell--disabled .editable-date--disabled {
            background: none;
          }
        }

        &.sequence-table__row--checked {
          background-color: $color-blue-6;

          &:hover {
            background-color: $color-blue-5;
          }
        }
      }

      .dropdown--status-cell {
        width: grid-px(15);
      }

      tr[role="none"] {
        height: 100%;
      }

      td {
        font-size: $font-size-standard;
        padding: grid-px() grid-px(2);
        white-space: nowrap;
        max-width: grid-px(75);
        @include text-overflow-ellipsis;
      }

      td[role="cell"] {
        border-bottom: grid-px(0.125) solid $color-gray-5;
        color: $color-gray-1;
        font-size: $font-size-standard;
        line-height: grid-px(2.5);
        white-space: nowrap;

        &.not-editable {
          cursor: default;
          padding: grid-px() grid-px(2);

          // used to show outline on the cell when it or any of its children are in focus
          // EditableCell manage their own outline state
          &.selected:focus-within {
            box-shadow: 0 0 0 grid-px(0.125) $color-blue-base inset;
            outline: none;
          }

          @include keyboard-focus-only;
        }
      }
    }

    &__header-cell {
      display: flex;

      .sort-arrow {
        visibility: hidden;
      }

      &:hover .sort-arrow {
        visibility: visible;
      }
    }

    .name-cell {
      &:focus {
        outline: none;
      }
    }

    &__add-btn {
      margin: grid-px(3) 0 !important;
      width: 100%;
    }
  }

  &__table-wrapper {
    overflow: scroll;
  }

  &__table-body {
    tr {
      background-color: $color-white;
    }

    &--searched-rows {
      tr {
        background-color: $color-green-6;
      }
    }
  }

  &__error {
    color: $color-red-base;
    margin-top: grid-px();
  }
}
