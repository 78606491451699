@import "../../../styles/index.scss";

.multi-controls {
    &__wrapper {
        cursor: default;
        min-height: grid-px(2);
        height: auto;
        padding: grid-px(0.5) grid-px(1.5) grid-px(1);
    }

    &__button {
        cursor: pointer;
        color: $color-blue-dark-3;
        font-weight: $title-font-weight;

        &:hover {
            color: $color-blue-1;
        }
    }
}
