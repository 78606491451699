@import '../../../../../../../styles/index.scss';

$multiple-edit-status-table_header_margin_top: 18px;
$multiple-edit-status-table_header_margin_bottom: 16px;
$multiple-edit-status-table_header_font_weight: 400;
$multiple-edit-status-table_header_line_height: 18px;

.multi-edit-priority {
  &__header {
    color: $text-dark;
    font-size: $font-size-small;
    margin-top: $multiple-edit-status-table_header_margin_top;
    font-weight: $multiple-edit-status-table_header_font_weight;
    line-height: $multiple-edit-status-table_header_line_height;
    margin-bottom: $multiple-edit-status-table_header_margin_bottom;
  }

  &__priority-dropdown-wrap {
    margin-bottom: $multiple-edit-status-table_header_margin_bottom;
  }

  &__priority-input-wrap {
    width: 280px;

    .TrekTextField-root {
      float: none;
      margin: 0 0 11px !important;
    }

    .TrekTextField-labelDisabled {
      color: $color-gray-2 !important;

      span {
        color: $color-gray-2 !important;
      }
    }
  }

}
