@import "../../styles/index.scss";
@import "../../common/Form/Form.scss";

.default-form-container {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__header {
    align-items: center;
    background-color: $color-white;
    border-bottom: grid-px(0.125) solid $color-gray-3;
    padding-left: grid-px(3);
    padding-right: grid-px(4);
    display: flex;
    flex-shrink: 0;
    justify-content: space-between;
    height: $ads-header-height;
    position: sticky;
    box-sizing: border-box;

    h1 {
      font-size: $page-header-font-size;
      font-weight: $page-header-font-weight;
      line-height: $page-header-line-height;
      color: $color-faux-black;
    }
  }

  &__header-actions {
    align-items: center;
    display: flex;
    height: grid-px(5);

    svg {
      margin-right: grid-px();
    }

    span {
      font-size: grid-px(1.5);
    }

    .TrekButton-label {
      text-transform: none;
      font-size: $font-size-standard;
      letter-spacing: 0.3px;
    }
  }

  &__body-container {
    display: flex;
    flex-direction: row;

    height: calc(#{$ads-content-height} - grid-px(2.25));

    .loading-content {
      margin: auto;
    }
  }

  &__save-btn.TrekButton-root:not(.TrekButton-disabled) {
    background: $color-blue-base;
    color: $color-white;
    &:hover {
      background: $color-blue-dark;
    }
    &:focus {
      outline-offset: grid-px(0.5);
    }
  }

  &__discard-changes-btn.TrekButton-root {
    padding-left: grid-px(2);
    padding-right: grid-px(2);
  }

  &__discard-changes-btn.TrekButton-root:not(.TrekButton-disabled) {
    background: $color-gray-5;
    color: $color-gray-1;
    &:hover {
      background: $color-gray-4;
    }
  }

  &__discard-changes-btn,
  &__save-btn {
    &.TrekButton-root.TrekButton-disabled {
      background: $color-gray-6;
      color: $color-gray-3;
    }
  }
}
