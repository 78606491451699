@import '../../../../../styles/index.scss';

.entity-details-drawer-content {
  align-items: center;
  background: none;
  border-right: 1px solid $color-gray-5;
  display: flex;
  flex-direction: column;
  margin: grid-px(2) 0px;

  &__selected-content {
    margin: grid-px(4) grid-px(2) grid-px(16) grid-px(3);

    &:first-child {
      position: relative;
    }

    &--title {
      margin: 0 0 grid-px() 0;
      font-size: 16px;
      line-height: 1.3;
      color: $color-faux-black;
      font-weight: $title-font-weight;
    }
  }

  &__img-icon {
    &--selected {
      display: none;
    }
    &--unselected {
      display: inline-block;
      margin: 0 auto;
    }
  }

  .TrekTabNavigationTab {
    &-root {
      height: grid-px(5);
      margin: grid-px(1.75) 0;
      width: grid-px(5);
      text-align: center;
      padding: 0px;

      &:first-child {
        margin-top: 0px;
      }

      &:active,
      &:focus {
        outline: none;
      }

      .trafficking-drawer-content__menu-button {
        margin: grid-px(1.375) auto;
        border: none;

        &--with-icon {
          background: none;
          text-align: center;
          height: 20px;
          padding: 0px;
          width: 20px;

          img {
            height: 100%;
            width: 100%;
            @include filter-gray-1;
          }
        }
      }

      svg path {
        fill: $color-gray-1;
      }
    }

    .trafficking-drawer-content__img-icon {
      &--selected {
        display: none;
      }

      &--unselected {
        display: inline-block;
        margin: 0 auto;
      }
    }

    &-selected {
      background: $color-gray-1;
      clip-path: circle(48%);

      svg path {
        fill: $color-white !important;
      }

      .trafficking-drawer-content__img-icon {
        svg path {
          fill: $color-white;
        }

        &--selected {
          display: inline-block;
          margin: 0 auto;
        }
        &--unselected {
          display: none;
        }
      }

      &::after {
        content: none;
      }
    }
  }

  &--expanded {
    align-items: flex-start;
    padding: grid-px(2);

    .TrekTabNavigationTab {
      width: 90%;
      padding: grid-px(1.25);
      border-radius: 10px;

      .trafficking-drawer-content__menu-button {
        margin: grid-px(1.375) grid-px(3) grid-px(1.375) 0;
      }

      .trafficking-drawer-content__img-icon {
        margin: 0 grid-px(3) 0 0;
      }

      &--selected {
        color: $white-background;
        clip-path: none;
      }
    }
  }
}
