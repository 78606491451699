@import '../../../../styles/index.scss';

.ad-list {
  background: $white-background;
  display: flex;
  flex-direction: column;
  min-width: grid-px(64);
  height: calc(#{$content-height} - #{$header-height});
  border-left: $light-border;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: grid-px(2) grid-px(3);
    border-bottom: $light-border;
    background-color: $color-white;
    position: sticky;
    box-sizing: border-box;
    height: $ads-header-height;

    h1 {
      font-size: 18px;
      font-weight: $page-header-font-weight;
      color: $color-faux-black;
    }
  }
}
