@import '../../styles/index.scss';

$title-badge-font-size: 10px;

.badge {
  background: $color-black-1;
  border-radius: 3px;
  color: $color-white-1;
  font-size: $title-badge-font-size;
  font-weight: bold;
  padding: 3px 4px;
  text-transform: uppercase;
}
