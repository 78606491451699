@import "../../../../styles/constants.scss";
@import "../../../../styles/colors.scss";
@import "../../../../common/Form/Form.scss";
@import "../../../../styles/mixins.scss";

.creative-builder {
  &__creative-name-input {
    max-width: grid-px(35);
  }

  &--gateway-go {
    .top-grid-container {
      margin-bottom: 0 !important;
    }
  }

  .text-field-container {
    margin: grid-px(3);
  }

  &__divider {
    color: $color-gray-3;
  }

  &__submit-msg {
    margin-right: 1em;
  }

  &__field-label {
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  .trek-label__required-flag {
    color: $color-gray-2;
  }

  .task-tab-navigation {
    width: 100%;

    > .task-tab-navigation__task-tab {
      border: none;
    }
  }

  .top-grid-container {
    @include form-grid-display;
    @include form-grid-child;
    grid-template-columns: grid-px(39) grid-px(24.375) grid-px(13.125);
    grid-template-rows: grid-px(8);

    .remove-creative-button {
      margin: 0;

      &:disabled {
        cursor: not-allowed;
        pointer-events: auto;
      }
    }
  }

  .alert {
    position: relative;

    .alert__icon {
      display: none;
    }

    .alert__content {
      box-sizing: border-box;
      width: 100%;
      padding: grid-px();
      margin: 0;
    }

    .alert__text {
      .alert__type {
        display: none;
      }
      .alert__header-text {
        margin-left: 0;
      }
      .alert__body-text {
        margin: 0;
        font-size: $font-size-small;
        line-height: grid-px(2.25);
        color: $text-black;
        p {
          margin: 0;
        }
        span {
          color: $color-blue-base;
          text-decoration: underline;
          cursor: pointer;
          padding-left: grid-px(0.25);
        }
      }
    }
    .alert__close-btn {
      display: none;
    }
  }
}
