@import '../../../../../styles/index.scss';

.option-asset-form {
  display: flex;
  justify-content: space-between;

  .asset-importer-field {
    width: grid-px(45);
    max-width: grid-px(45);
  }

  &__side-block {
    .dropdown {
      margin: grid-px(3);
      width: grid-px(30) !important;

      .tooltip__container {
        margin: 0;
      }

      .dropdown-list__option {
        padding: grid-px(1.5) grid-px(0.75);
      }
    }

    .text-field-container {
      width: grid-px(30) !important;
    }

    .tooltip__container {
      margin: grid-px(3);

      .text-field-container {
        margin: 0;
      }
    }
  }

  &__random-order {
    input {
      width: grid-px(10) !important;
    }
  }
}
