@import '../../../../styles/index.scss';

$ad-list-item-hover-bg-color: $color-gray-6;
$ad-list-item-selected-bg-color: $color-blue-6;

.ad-list-item {
  list-style: none;
  padding: grid-px(2) grid-px(4);
  border-bottom: $light-border;
  display: flex;

  .ad-list-item__percentage {
    color: $color-gray-2;
    font-size: 11px;
    letter-spacing: 0.25px;
    min-width: grid-px(3);
    padding: 14px 14px 0 0;
  }

  .unsaved-changes {
    display: inline-flex;
    align-items: center;

    svg {
      height: grid-px(2);
      margin: 0 0 0 -4px;
    }

    font-style: italic;
    font-size: grid-px(1.5);
  }

  &--selected {
    background: $ad-list-item-selected-bg-color;

    .ad-list-item__name {
      color: $color-faux-black;
    }
  }

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__actions {
    display: flex;
    align-items: center;
  }

  &__action {
    cursor: pointer;
    margin-left: grid-px(2);

    svg path {
      fill: $color-gray-2;
    }

    &:hover {
      svg path {
        fill: $color-faux-black;
      }
    }
  }

  &__name {
    font-size: 16px;
    font-weight: 500;
    color: $color-gray-2;
    @include text-overflow-ellipsis;
    max-width: calc(100vw - 1155px);

    &--no-ad {
      font-style: italic;
    }
  }

  &__pills {
    margin: grid-px() auto;

    .pill {
      margin-right: grid-px();
    }

    .transcode-status {
      display: block;
      margin-top: grid-px();
      max-width: max-content;
    }
  }

  &__content-container {
    flex-grow: 1;
  }
}
