@import '../../../../../../../../../styles/index.scss';

.buffer-multi-edit-section {
  &__header{
    margin-top: 5px;
    margin-bottom: 15px;
    line-height: 18px;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    color: $text-dark;
  }

  &__container {
    width: 150px;

    &:has(.TrekAlertHeader-error) {
      width: 350px;
    }

    .editable-percent-input {
      margin-bottom: 48px;
    }
  }

  &__wrapper-percent-input {
    display: flex;
    align-items: center;

    span:last-child {
      margin-left: 5px;
    }
  }
}
