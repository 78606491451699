@import '../../../styles/index.scss';
@import '../../../common/Form/Form.scss';

.ad-schedule {
  display: block;

  &__card-outer-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__location-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: grid-px(2);

    .globe-icon {
      margin-right: grid-px(0.5);
    }
  }

  &__location-text {
    display: flex;
    color: $color-gray-2;
    font-size: 11px;
    line-height: 1.3;
    vertical-align: middle;
  }

  &__date-title {
    display: flex;
    margin-bottom: grid-px();
    align-items: center;

    h5 {
      margin: 0;
      color: $color-gray-2;
      font-size: 18px;
      font-weight: 500;
    }

    svg {
      margin-right: grid-px();
      path {
        fill: $color-gray-2;
      }
    }
  }

  .dates-info-content {
    .timezone-wrapper {
      margin: grid-px() 0 grid-px();
      max-width: grid-px(36);
    }

    &__fieldset {
      display: block !important;
    }

    &__validation {
      margin: grid-px(1) 0;
    }

    &__date-range-list-items {
      max-height: grid-px(35);
    }

    &__date-range-list {
      overflow: auto;
      max-width: auto;
    }
  }
}
