@import '../../../../../../../../styles/index.scss';

.multiple-edit-dates-info {
  display: block;

  .dates-info-content {
    .timezone-wrapper {
      margin: grid-px() 0 grid-px();
      max-width: grid-px(36);
    }

    &__fieldset {
      display: block;
    }

    &__validation {
      margin: grid-px(1) 0;
    }

    &__date-range-list-items {
      padding-left: grid-px(0.1);
      max-height: grid-px(35);
    }

    &__date-range-list {
      overflow: auto;
      max-width: auto;
    }
  }
}
