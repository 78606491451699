@import '../../../styles/index.scss';

.creative-mode-selector {
  margin: grid-px(3);
  display: flex;
  justify-content: space-between;
  align-items: center;

  > button > span {
    width: grid-px(29);
  }

  > span {
    color: $color-gray-2;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 1px;
  }
}
