@import '../../../../../../styles/index.scss';

.asset-option {
  &__name {
    word-break: break-word;
    font-size: 13px;
    padding-bottom: grid-px(0.5);
  }

  &__id {
    font-size: 10px;
  }
}
