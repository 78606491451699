@import '../../styles/index.scss';

.daterange {
  margin-bottom: grid-px(4);

  &__row {
    display: flex;
    align-items: flex-end;
    margin-top: grid-px();
    width: 100%;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;

    // @-moz-document selectors added to adjust width of the input on firefox,
    // because ::-webkit-datetime-edit-minute-field doesn't work to remove minutes
    & > .datetime-input {
      padding-top: 9px;
      padding-bottom: 10px;
      font-size: $font-size-standard;
      margin-right: grid-px();
      border-radius: grid-px(0.5);
      border: 1px solid $border-color-light;

      @-moz-document url-prefix() {
        max-width: 200px;
      }

      .datetime-input__date-input {
        text-align: center;

        @-moz-document url-prefix() {
          max-width: 110px;
        }
      }

      .datetime-input__time-input {
        max-width: 42px;
        @-moz-document url-prefix() {
          max-width: 100px;
          margin-left: -10px;
        }
      }

      &--readonly {
        border: none;

        .datetime-input__date-input {
          text-align: left;
        }
      }

      &--invisible {
        visibility: hidden;
      }
    }

    .dropdown--select-timezone {
      width: 100%;
    }
  }

  &__title {
    color: $color-black;
    text-transform: uppercase;
    font-size: $font-size-small;
    margin-bottom: grid-px();
    font-weight: 600;
  }

  &__separator {
    margin: grid-px() grid-px() grid-px() 0;
    font-size: $font-size-standard;
    line-height: 1.3;
    color: $color-black;
    
    &--invisible {
      visibility: hidden;
    }
  }

  &__infinity {
    height: grid-px(4.25);
    margin: 0 grid-px();
    display: flex;
    align-items: center;
  }
}
