// Hulu design tokens
@import '~@hulu/design-tokens/lib/colors.scss';

// Page
$page-background: $color-gray-6;
$white-background: $color-white;

// Text
$text-black: $color-faux-black;
$text-dark: $color-gray-1;
$text-light: $color-gray-3;

// Borders
$border-color-dark: $color-gray-1;
$border-color-light: $color-gray-3;
$border-color-header: $color-gray-4;

// Shadow
$box-shadow-color-dark: $color-black-5;
$box-shadow-color-light: $color-black-9;

// Links
$link-blue: $color-blue-1;

// Rows
$onhover-light-gray: $color-gray-5;

// Custom Colors
$color-red-dark: #b91740;
$color-red-input-error: #de1b4b;
$color-blue-dark: #135dbf;
$color-blue-dark-1: #1352a6;
$color-blue-dark-3: #176ee1;
$color-green-dark: #0a764f;
$color-grey-dark: #212329;
$color-grey-light: #646f85;
$color-title-gray: #292c33;
$color-brand-primary: #26d997;
$color-black: #000000;
$color-black-1: #040405;
$color-yellow-dark: #b58b00;
$color-orange-1: #ff8c00;
$color-orange-3: #fcd17c;
$color-orange-6: #fff7e7;
$color-orange-base: #ffa500;

// Filters (for use with CSS rules for SVG img elements)
@mixin filter-white {
  filter: brightness(255) saturate(100%);
}

@mixin filter-gray-1 {
  filter: invert(15%) sepia(10%) saturate(1013%) hue-rotate(180deg) brightness(93%) contrast(91%);
}
@mixin filter-gray-2 {
  filter: brightness(0) saturate(100%) invert(44%) sepia(11%) saturate(747%) hue-rotate(182deg) brightness(95%)
    contrast(92%);
}

@mixin filter-gray-3 {
  filter: brightness(0) saturate(100%) invert(87%) sepia(14%) saturate(183%) hue-rotate(182deg) brightness(87%)
    contrast(89%);
}

@mixin filter-gray-4 {
  filter: brightness(0) saturate(100%) invert(59%) sepia(11%) saturate(105%) hue-rotate(314deg) brightness(90%)
    contrast(84%);
}

@mixin filter-gray-disabled {
  filter: brightness(3) invert(1);
}

@mixin filter-faux-black {
  filter: brightness(0) saturate(100%) invert(3%) sepia(5%) saturate(726%) hue-rotate(201deg) brightness(91%)
    contrast(102%);
}

@mixin filter-red-base {
  filter: invert(19%) sepia(65%) saturate(3626%) hue-rotate(330deg) brightness(98%) contrast(100%);
}

@mixin filter-red-2 {
  filter: invert(49%) sepia(66%) saturate(947%) hue-rotate(308deg) brightness(95%) contrast(91%);
}

@mixin filter-blue-1 {
  filter: invert(48%) sepia(50%) saturate(5129%) hue-rotate(200deg) brightness(98%) contrast(87%);
}

@mixin filter-blue-2 {
  filter: invert(41%) sepia(93%) saturate(5892%) hue-rotate(207deg) brightness(99%) contrast(82%);
}
