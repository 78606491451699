@function grid-px($multiple: 1) {
  @return $multiple * 8px;
}

$default-padding: grid-px(2);

$font-size-big: 16px;
$font-size-standard: 14px;
$font-size-small: 12px;

$header-height: grid-px(8);
$footer-height: 50px;

$font-weight-bold: 600;
$font-weight-normal: 400;

// Page Title
$title-height: 62px;
$title-padding: (2 * $default-padding) 0 0 (2 * $default-padding);
$title-font-size: 24px;
$title-font-weight: 500;

// Page Header
$page-header-font-size: 18px;
$page-header-font-weight: 600;
$page-header-line-height: 24px;

// Height remaining when you account for header and footer
$content-height: calc(100vh - #{$title-height} - #{$footer-height});

// Borders
$border-radius-standard: 4px;
$border-light: 0.5px solid rgba(0, 0, 0, 0.04);

// Tables
$cell-height: grid-px(6);
$table-background: #fff;
$table-border: #b9bfcb;

// Shadows
$shadow-height-standard: 2px;
$raised-shadow-standard: 0px $shadow-height-standard 4px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14),
  0px 1px 10px rgba(0, 0, 0, 0.12);
$raised-shadow-clip-path: inset(0px -5px -5px -5px);
$tooltip-shadow: 0 4px 5px rgba(0, 0, 0, 0.2), 0 7px 10px rgba(0, 0, 0, 0.14), 0 2px 16px rgba(0, 0, 0, 0.12);
$drag-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
$sticky-filter-drop-shadow: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.1));

// Height of sticky header for AdForm and AdList
$ads-header-height: grid-px(8);

// Height of content
$ads-content-height: calc(#{$content-height} - #{$ads-header-height});

// Margin between form elements
$form-element-margin-column: grid-px(3);
$form-element-margin-row: grid-px(4);
$form-element-margin: $form-element-margin-row $form-element-margin-column;
$form-element-margin-top: $form-element-margin-row 0;
