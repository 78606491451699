@import '../../../styles/index.scss';
@import '../styles/dimensions.scss';

.action-buttons {
  position: absolute;
  left: $trafficking-tab-nav-width;
  transform: translateY(20%);
  margin: 0;
  z-index: 15;

  &__wrapper {
    display: flex;
    align-items: center;
    gap: grid-px();
  }
}
