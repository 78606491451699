@import '../Form.scss';

.TrekParagraphInput-root.TrekParagraphInput-root {
  margin: $form-element-margin;
  float: none;

  textarea {
    resize: none;
  }
}
