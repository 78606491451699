@import "../../../../../././../styles/index.scss";

.viewability-vendor-form {
  &__label {
    font-size: grid-px(2.225);
    font-weight: normal;
    line-height: grid-px(3);
    margin-bottom: grid-px(3.5);
  }

  .viewability-vendor {
    display: flex;
  }

  .form-multiselect-dropdown {
    width: 280px;
    margin-left: 0;
    margin-top: grid-px(1);
  }

  .TrekTextField-root {
    margin-top: grid-px(1) !important;
  }

  &__tags-table {
    max-height: 400px;
    overflow: auto;
    margin-bottom: grid-px(3);

    table {
      border-collapse: separate;
      border-spacing: 0;
      width: 100%;
    }

    thead {
      position: sticky;
      left: 0;
      top: 0;
      z-index: 6;
      background-color: $table-background;
    }

    th,
    td {
      border-bottom: 1px solid $color-gray-4;
    }

    tr:last-child {
      td {
        border-bottom: none;
        text-align: right;
      }
    }

    .ad-tags-table {
      &__type-head {
        width: 120px;
      }

      &__verified-head {
        width: fit-content;
      }

      &__event-head {
        width: 120px;
      }
    }

    .ad-tags-table-rows {
      &__cell-remove {
        svg {
          width: 100% !important;
        }
      }
    }
  }

  &__action-buttons {
    margin-top: grid-px;
    display: flex;
    justify-content: flex-end;
  }

  &__cancel-button {
    border: none !important;
    color: $color-blue-1 !important;
  }

  .ias-campaign-line-item-id-field {
    flex-grow: 1;
  }
}
