@import "../../../styles/index.scss";

.trafficking-toggle-section {
    position: relative;

    .toggle-section {
        position: relative;
    }
    
    .toggle-section__title-content-wrapper {
        padding: grid-px(1.5);
    }
    
    .toggle-section__title {
        div {
            height: grid-px(4);
            display: flex;
            align-items: center;
        }
    }

    .selected-items-pill {
        margin-left: grid-px(2);
    }
}
