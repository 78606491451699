@import '../../../../././../styles/index.scss';
@import '../../../../../styles/colors.scss';
@import '../../../../../styles/mixins.scss';

.name-cell {
  @include sticky-cell-position;
  
  &:focus {
    outline: none;
  }
  
  &__text {
    @include text-overflow-ellipsis;
    color: $color-blue-base;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
