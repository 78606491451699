@import '../../../styles/index';

.group-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: grid-px(5);

  &__title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: $color-black;
    white-space: nowrap;
    @include text-overflow-ellipsis;
  }

  &__icons-wrapper {
    display: flex;
    height: grid-px(3);
  }

  &__icon {
    cursor: pointer;

    &--edit {
      padding-right: grid-px(0.5);
    }

    &--delete {
      margin-left: grid-px(1.6);
      width: grid-px(3);
      background-color: white;
      padding: 0;
      border: none;
    }

    &--disabled {
      cursor: default;

      * {
        fill: $color-gray-4;
      }
    }
  }

  &__edit-name-wrapper {
    display: block;
    flex: 1;
  }
}
