@import '../../../styles/index.scss';
@import '../../AdTagsTable/AdTagsTable.scss';

.tags {
  display: block;

  table.ad-tags-table {
    margin: grid-px();
  }

  &__nielsen-error {
    color: $color-red-input-error;
    margin: grid-px();
    padding-top: $table-cell-padding;
    padding-left: $table-cell-padding;
  }

  &__nielsen-warning {
    color: $color-gray-2;
    margin: grid-px();
    padding-top: $table-cell-padding;
    padding-left: $table-cell-padding;
  }
}
