@import '../../../../../../styles/index.scss';

$multiple-edit-arrow-width: 1px;
$multiple-edit-arrow-padding: 12px;
$multiple-edit-badge-line-height: 10px;
$multiple-edit-arrow-border: 1px solid $color-grey-light;

.multiple-edit-not-first-step-title {
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;

  &__arrow {
    padding-right: $multiple-edit-arrow-padding;
    border-right: $multiple-edit-arrow-border;
    display: flex;
    align-items: center;
    height: 100%;
    margin-right: grid-px(2);

    svg {
      cursor: pointer;
    }
  }

  &__badge {
    line-height: $multiple-edit-badge-line-height;
    margin-right: grid-px();
    white-space: nowrap;
  }

  &__entities-names {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
