@import '../../../styles/index';

.drag-drop-item {
  display: flex;
  align-items: center;
  padding: grid-px(0.9) 0;

  &__icon {
    margin-right: grid-px(1.875);
    margin-left: grid-px(1.25);
    min-width: grid-px(3);

    path {
      fill: $color-gray-3;
    }
  }

  &__element {
    flex: 1;
    overflow: hidden;
  }
}
