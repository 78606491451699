@import '../../../../../styles/index';

.multiple-change-log-cell {
  font-size: $font-size-small;
  margin-bottom: grid-px(3);
  display: flex;
  flex-direction: row;
  padding: 0;
  list-style: none;

  &__plus-icon {
    margin-top: -2px;
    margin-right: 3px;
    align-self: center;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &--removal,
  &__tags--removal {
    text-decoration: line-through;
    text-decoration-color: $color-red-input-error;

    ul {
      flex-direction: column;
    }
  }

  li {
    position: relative;
    &:first-of-type {
      padding-bottom: grid-px(0.1);
    }
  }

  &__priority,
  &__tier,
  &__oms-link,
  &__type-properties,
  &__tags,
  &__tags--removal {
    padding-left: grid-px(2);
    max-width: grid-px(17.5);
  }

  &__priority,
  &__secondary-pace,
  &__tier,
  &__oms-link,
  &__type-properties,
  &__freq-cap,
  &__freq-cap-border {
    font-size: $font-size-small;
    list-style: square;

    ul,
    .addition {
      list-style-type: none;
      padding-left: grid-px(2);
      vertical-align: top;
    }

    li {
      &:first-of-type {
        font-weight: normal;
      }
    }
  }

  &__daypart,
  &__date-range {
    font-size: $font-size-small;
    list-style: none;
    padding: 0;
    margin: 0;

    &--removal {
      text-decoration: line-through;
      text-decoration-color: $color-red-input-error;
    }

    li,
    li:first-of-type {
      padding-bottom: grid-px(0.5);
      &:last-of-type {
        padding-bottom: 0;
      }

      b {
        display: inline-block;
        min-width: 40px;
      }
    }
  }

  &__date-range-simple-value {
    height: grid-px(4.625);
  }

  &__date-range-wrapper  {
    display: flex;
    margin-bottom: grid-px(1.25);

    .timezone-value {
      font-size: grid-px(1.375);
    }
  }

  &__freq-cap,
  &__freq-cap-border {
    position: relative;
    font-size: $font-size-small;
    margin: 0 0 grid-px(0.75) 0;

    .multiple-change-log-cell__plus-icon {
      position: absolute;
      padding-top: 2px;
    }

    &--removal {
      text-decoration: line-through;
      text-decoration-color: $color-red-input-error;
    }

    ul {
      list-style-type: none;
    }
  }

  &__freq-cap {
    border-bottom: 1px solid $border-color-light;
  }

  &__targeting-rules,
  &__term-list {
    ul {
      padding-left: grid-px(2);
    }
  }

  &__term-list-title {
    font-weight: bold;
  }
  &__term-list-item {
    &--removed {
      text-decoration: line-through;
      text-decoration-color: $color-red-input-error;
    }
    &--added {
      list-style-type: none;

      .multiple-change-log-cell__plus-icon {
        position: absolute;
        left: -17px;
        bottom: 0;
      }
    }
  }
  &__term-list-item-type {
    &::after {
      content: ':';
    }
    font-style: italic;
    margin-right: 0.25em;
  }

  &--default-list,
  &--default-list-nested {
    display: block;
    li {
      display: block;
      &:first-of-type {
        font-weight: normal;
        padding-bottom: 0;
      }
      .plus-icon {
        width: 10px;
        height: 10px;
      }
    }
  }

  &--default-list-nested {
    padding-left: grid-px();
  }
}
