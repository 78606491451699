@import '../../../styles/index.scss';

.ad-edit-cell {
  &__displayed-date {
    padding-left: grid-px(2);
    @include text-overflow-ellipsis;
  }

  &__date-input {
    background: $white-background;
  }

  &__modal {
    .dates-info {
      &__date-range-list-title {
        &-start {
          width: grid-px(25.25);
        }
      }
    }
  }
}
