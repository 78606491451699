@import '../Form.scss';

.text-field-container {
  .TrekTextField-root {
    float: none;
    margin: $form-element-margin-top;
  }

  .TrekTextField-labelDisabled {
    color: inherit !important;
    
    span {
      color: inherit;
    }
  }
}
