@import '../../../../../styles/index.scss';
@import '../../../../Trafficking/styles/index';

.table-filters {
  &__modal {
    background-color: $color-white;
    color: $color-black;

    max-height: 80vh;
    min-width: grid-px(46);
    overflow: auto;

    padding-bottom: 4px;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
  }

  &__controls {
    color: $color-blue-dark-3;
    font-weight: $title-font-weight;
  }

  &__controls-container {
    cursor: pointer;
    min-height: 36px;
    height: auto;
    padding: grid-px(1);
    padding-left: grid-px(2);
  }
}

.filter-tooltip {
  opacity: 1 !important;
  padding: 0 !important;
}

.rc-tooltip {
  top: -1000px;
  left: -1000px;
}

.rc-tooltip-arrow {
  display: none;
}

.rc-tooltip-inner {
  padding: 0 !important;
}

.filter-dropdown {
  font-size: $font-size-small;
  width: 100%;
  position: relative;
  background: transparent;

  &__header {
    cursor: pointer;
    height: grid-px(4);
    background-color: $color-gray-6;
    box-shadow: inset 0px -1px 0px $color-gray-4;
  }

  &__chevron-icon {
    display: inline-block;
    padding: grid-px(1);
  }

  &__label {
    cursor: pointer;
    font-weight: $page-header-font-weight;

    span {
      line-height: grid-px(2.25);
      color: #586174;
      text-transform: initial;
    }
  }

  &__clear {
    float: right;
    padding: grid-px(1) grid-px(1) 0 0;
    font-weight: $page-header-font-weight;
    color: $color-blue-dark-3;
  }

  &__checkbox-container {
    cursor: pointer;
    height: 36px;
    background-color: $color-white;
    color: $color-black;
    padding: grid-px(1);
    padding-left: grid-px(2);

    div {
      width: grid-px(31.25);
    }

    label {
      max-width: grid-px(25);

      span {
        text-transform: initial;
        cursor: pointer;
        font-weight: 400;
        line-height: grid-px(2.25);
      }
    }

    &:hover {
      background-color: $color-gray-6;
    }
  }

  .dropdown-button button.trek-dropdown {
    border-radius: 0;

    svg {
      width: grid-px(2.25);
    }
  }
}
