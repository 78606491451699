@import "../../../../styles/index";

.duplicate-line-items-picker {
  display: flex;
  flex-direction: column;
  box-shadow: $box-shadow-dark;
  height: 500px;
  flex: 1;
  overflow: hidden;

  &__delete-button {
    display: flex;
    justify-content: center;
  }

  .duplicate-line-items-picker__delete-icon {
    @include table-outlined-button;
    height: 21px;
    width: 21px;
  }

  &__table-wrapper {
    overflow: auto;
    flex: 1;

    table {
      width: 100%;
    }
  }

  &__table-status {
    display: flex;
    justify-content: center;
  }

  &__header {
    position: sticky;
    left: 0;
    top: 0;
    border-bottom: 1px solid $color-gray-4;
    background: $white-background;
    z-index: 1;

    tr th:first-of-type {
      .duplicate-line-items-picker__header-cell-container:first-of-type {
        text-overflow: unset;
      }
    }
  }

  &__row {
    border-bottom: 1px solid $color-gray-5;

    td:first-of-type {
      .duplicate-line-items-picker__cell-container {
        text-overflow: unset;
      }
    }

    td {
      display: flex;
      align-items: center;
    }

    .controlled-checkbox {
      height: 16px;
    }
  }

  &__header-cell-container,
  &__cell-container {
    padding: grid-px(0.9) grid-px(1.25);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    font-weight: normal;

    .tooltip__container {
      justify-content: flex-start;
      text-wrap: wrap;
      overflow-wrap: anywhere;
    }
  }

  &__search-container {
    padding: grid-px(1.25);
  }

  &__search {
    border: 1px solid $color-gray-4;
    border-left: 1px solid $color-gray-4 !important;
    border-radius: grid-px(0.75);
    max-width: 100%;

    input {
      font-size: grid-px(1.875);
    }

    svg {
      width: 15px;
      height: 15px;
    }
  }

  &__checkbox {
    accent-color: $color-black;
  }

  &__error-message {
    margin-top: grid-px(2);
    font-size: $font-size-big;
    color: $color-red-input-error;
  }

  &__scroll-loader {
    & img {
      display: block;
      margin: grid-px();
      height: grid-px(4);
    }
  }
}
