@import '../../styles/index.scss';

.error-message {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: 868px;
  margin: grid-px(6) auto 0;
  background-color: $color-red-6;
  border: 1px solid $color-red-base;

  &__content {
    display: flex;
    padding: grid-px(3);
    color: $color-faux-black;
  }

  &__icon {
    margin: 0 grid-px(2) 0 0;
  }

  &__text-container {
    align-self: center;
  }

  &__header-text {
    flex: 1 1 auto;
    text-align: left;
    line-height: 1.33;
    font-size: 16px;
    font-weight: normal;
    margin: 0 0 grid-px(2) 0;
  }

  &__email-link {
    color: $color-blue-1;
  }

  &__message-text {
    font-size: $font-size-small;
    line-height: 1.33;
    text-align: left;
    word-break: break-word;
    margin: 0;
  }

  &__bold {
    font-weight: 700;
  }
}
