@import "../../../../styles/index.scss";

.ad-list-items {
  list-style: none;
  margin: 0 auto;
  padding: 0px;
  width: 100%;
  overflow-y: auto;

  &__no-ads-message {
    margin-top: grid-px(4.375);
  }

  &__toggle-container {
    .list-toggle__header {
      padding: grid-px(2) grid-px();
      border-top: 1px solid $color-gray-4;
      border-bottom: 1px solid $color-gray-2;
    }

    .list-toggle__children {
      margin-top: 0;
    }
  }

  &__list-name {
    width: grid-px(50);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    color: #000000;
    white-space: nowrap;
    @include text-overflow-ellipsis;
  }

  &__list-type {
    font-size: grid-px(1.5);
    color: $color-gray-2;
    @include text-overflow-ellipsis;
  }
}

.loading-content {
  margin: auto;
}
