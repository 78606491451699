@import '../../../../styles/index.scss';

.basic-select-button {
  display: flex;
  align-items: center;
  min-height: grid-px(4);
  padding: 0 grid-px(2);
  font-size: grid-px(1.5);
  background-color: $color-black;
  border-radius: grid-px(0.5);
  cursor: pointer;

  span {
    margin: 0 grid-px(1);
    color: $color-white;

    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &:hover {
    background-color: $color-black-3;
  }

  &__chevron-icon {
    > path {
      fill: $color-white;
    }
    margin-left: auto;

    &--open {
      transform: rotate(180deg);
    }

    &--closed {
      transform: rotate(0deg);
    }
  }

  &__active {
    background-color: $color-black-1;
  }
}
