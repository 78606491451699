@import "../../styles/index.scss";

.inventory-block-form {
    &__title {
        font-size: grid-px(2.75);
        margin-bottom: grid-px(3);
    }

    &__text {
        font-size: grid-px(2);
    }

    &__industry-title {
        display: flex;
        justify-content: space-between;
        margin-bottom: grid-px(2);

        .TrekSearchInput-root {
            border: 1px solid $color-solid-black-6;
            border-radius: grid-px(0.5);
            width: grid-px(40);
        }
    }

    &__industry-section {
        display: flex;
    }

    &__industry-empty {
        flex-grow: 1;
        text-align: center;
        font-size: grid-px(2);
        color: $color-gray-2;
        padding: grid-px(2) 0;
    }

    &__industry-pill {
        padding: grid-px(0.5) grid-px(1);
        margin-right: grid-px(1);
        border: 1px solid $color-gray-4;
        border-radius: grid-px(2);
        background-color: $color-gray-6;
    }

    &__targeting-section {
        margin-top: grid-px(3);

        table {
            margin-top: grid-px(1.5);
        }
    }
}
