@import "../../../styles/index.scss";

.sequence-content {
    padding: grid-px(4);
    border: none;
    width: 100%;

    .sequence-table {
        margin-bottom: grid-px(4);
    }

    &__add-sequence {
        margin: 0 0 grid-px(4) !important;
        width: 100%;

        span {
            color: $color-black-1;
        }
    }
}
