@import '../../styles/index.scss';

.dates-info {
  margin-bottom: grid-px(3);
  
  &__message,
  &__validation {
    font-size: $font-size-small;
    line-height: 1.3;
    color: $color-gray-1;
    margin: grid-px(0.5) 0 grid-px();
  }
}
