@import "../styles/index";

.page-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  .loading {
    width: grid-px(9.375);
    height: grid-px(9.375);
  }
}
