@import '../../styles/index.scss';

.card {
  background: $white-background;
  border: $light-border;

  &__title {
    color: $text-black;
    font-size: 18px;
    font-weight: bold;
    padding-bottom: $default-padding;
  }
}
