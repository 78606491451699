@import '../../../styles/index.scss';
@import '../../../common/Form/Form.scss';

.preview-wrapper {
  margin: $form-element-margin;
  float: none;
  display: block;

  &__content {
    margin: 0;
    font-size: $font-size-standard;
    line-height: grid-px(2.625);
    height: grid-px(7.5);
    overflow-y: auto;
    overflow-wrap: break-word;
  }
}
