@import '../../../styles/index.scss';

.editable-rotation-name {
  font-size: $font-size-standard;
  // must be relative so that modal is positioned correctly
  position: relative;
  height: $cell-height;
  width: 100%;

  &__display {
    font-size: $font-size-standard;
    height: $cell-height;
    line-height: $cell-height;
    @include text-overflow-ellipsis;

    &:hover {
      cursor: pointer;
    }
  }

  &__rendered-value {
    line-height: grid-px(5);
    vertical-align: middle;
    padding-left: grid-px(2);
  }

  & .editable-cell-modal__wrapper {
    height: initial;
    box-shadow: $raised-shadow-standard;
  }

  & .editable-cell-modal__content {
    padding: grid-px(1.5);
    background: $color-white;
    border-bottom: 1px solid $border-color-light;
    z-index: $modal-z-index;
  }

  // Modal footer
  & .editable-cell-modal__buttons {
    z-index: $editable-dropdown-menu-z-index;
    background: $color-white;
    padding: grid-px();
    margin: 0;

    & .TrekAlert-error {
      margin-right: auto;
    }

    & button {
      box-shadow: none;
      cursor: pointer;
    }
  }
}
