@import '../../styles/index';

.table-status {
  display: block;
  max-width: 100vw;
  position: sticky;
  top: 0;
  left: 0;
  overflow: hidden;

  &__status-text {
    color: $color-gray-2;
    font-size: $font-size-standard;
    line-height: 2;
    margin: grid-px(7) 0;
    text-align: center;
  }
}
