@import '../../../../../../../styles/index.scss';

.line-item {
  &:not(:last-child) {
    margin-bottom: grid-px(6.5);
  }

  &__line-item-input {
    margin-bottom: grid-px(1.875);
  }

  &__input-wrapper {
    display: flex;
    flex-direction: column;
  }

  &__label-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: grid-px();
  }
}
