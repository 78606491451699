//@import 'src/styles/index';
@import "../styles/index";

.selected-items-pill {
  display: flex;
  margin-left: auto;
  flex-direction: row;
  align-items: center;
  width: fit-content;
  height: grid-px(4);
  background: $color-gray-4;
  border-radius: grid-px(2);
  cursor: default;

  svg {
    height: 12px;
    width: 12px;
  }

  &__icon-close {
    margin-left: auto;
    margin-right: 10.5px;
    cursor: pointer;
  }

  .TrekPill-root {
    background-color: inherit;
    height: grid-px(1.5);
    white-space: nowrap;
    margin: 0 grid-px(1.3125);
    font-size: $font-size-small;
    font-weight: normal;
    color: $color-gray-1;
    text-transform: capitalize;
  }

  &:hover {
    background: $color-gray-3;
  }
}
