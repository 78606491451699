@import '../../styles/index.scss';

.TrekTextField-root {
  position: relative;

  input {
    width: 100%;
    border-radius: grid-px(0.5);
    color: $color-gray-2;
    text-overflow: ellipsis;

    &:hover {
      padding: none;
    }

    &:disabled {
      background-color: $color-gray-5;
      pointer-events: none;
    }
  }

  .TrekHelpText-error {
    position: absolute;
    top: grid-px(5);
    left: grid-px(1.625);
  }
}
