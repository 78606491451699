@import '../../../../styles/index.scss';

.basic-select-options {
  background-color: $color-white;
  color: $color-black;

  max-height: 340px;
  min-width: 138px;
  overflow: auto;

  border-radius: 0 0 grid-px(0.5) grid-px(0.5);
}
