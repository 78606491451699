@import '../../../../../../../styles/index.scss';

$multiple-edit-targeting-table_header_margin_top: 5px;
$multiple-edit-targeting-table_header_font_weight: 400;
$multiple-edit-targeting-table_header_line_height: 18px;
$multiple-edit-targeting-table_dropdown_button_width: 136px;

.multiple-edit-targeting-table {
  &__header {
    margin-top: $multiple-edit-targeting-table_header_margin_top;
    margin-bottom: grid-px(2);
    color: $text-dark;
    font-size: $font-size-small;
    font-weight: $multiple-edit-targeting-table_header_font_weight;
    line-height: $multiple-edit-targeting-table_header_line_height;
  }

  .targeting-dropdowns {
    margin-bottom: grid-px(3);

    button {
      padding: grid-px(1.5) grid-px(2.25);
      min-width: $multiple-edit-targeting-table_dropdown_button_width;
    }
  }
}
