@import '../../../../styles/index';

.review-content {
  color: $color-gray-1;

  &__title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: grid-px();
  }

  &__title {
    font-size: 22px;
    margin: 0;
  }

  &__prompt {
    font-size: 16px;
  }

  &__loading-spinner-wrapper {
    img {
      height: grid-px(3.75);
      width: grid-px(6.25);
    }
  }

  &__toast {
    width: 265px;
    white-space: nowrap;
    box-sizing: border-box;

    .ant-notification-notice-message {
      text-transform: none;
      font-weight: normal;
      margin-left: 32px;
      font-size: $font-size-standard;
    }
  }

  &__text-area {
    margin: 0;
  }

  .TrekTextField-root {
    margin: 0;
  }

  &__text-area-wrap {
    position: relative;
  }

  &__clipboard-icon {
    background-color: $color-faux-black;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    .TrekButton-root {
      margin: 0;
      padding: 0;
      width: 40px;
      height: 40px;
      border: none;
    }

    svg {
      width: 18px;
      height: 18px;

      path {
        fill: $color-white;
      }
    }
  }
}
