@import '../../../../../../styles/index.scss';

$multiple-edit-drawer-content-header-padding: 20px 0px;
$multiple-edit-drawer-content-header-button-padding: 13px 24px;

.multiple-edit-drawer-content-header {
    color: $color-black-1;
    font-size: $font-size-big;
    font-weight: $title-font-weight;
    line-height: $page-header-line-height;
    padding: $multiple-edit-drawer-content-header-padding;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
        margin: 0px;
        padding: $multiple-edit-drawer-content-header-button-padding;
    }
    
    .button__text {
        font-size: $font-size-standard;
        line-height: $font-size-standard;
        font-weight: $title-font-weight;
        letter-spacing: 0.3px;
        text-transform: capitalize;
    }
}
