@import "../../styles/index.scss";

.list-toggle {
  &__header {
    display: flex;
    align-items: center;
  }

  &__header-element {
    flex: 1;
  }

  &__icon {
    cursor: pointer;
    margin-right: grid-px();
    transition: transform 150ms linear;

    &--expanded {
      transform: rotate(180deg);
    }
  }

  &__children {
    margin-top: grid-px(0.5);
  }

  @include toggle-transition;
}
