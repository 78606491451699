@import '../../styles/index.scss';

.side-nav {
  background: $white-background;
  display: flex;
  border-right: $light-border;

  &__nav-container {
    display: flex;
    flex-direction: row;
    width: grid-px(19);
    margin: 23px 0;
    height: fit-content;
  }

  &__nav-indicator {
    display: flex;
    flex-direction: column;
    width: grid-px(0.5);
    background-color: $color-gray-5;
  }

  &__indicator {
    width: grid-px(0.5);
    height: 34px;
    margin-bottom: grid-px(2.375);

    &--active {
      background-color: #26d997;
    }

    &:last-child {
      margin-bottom: grid-px(0);
    }
  }

  &__nav-items {
    display: flex;
    flex-direction: column;
    margin-top: 8px;
    margin-left: 20px;

    span.TrekButton-outlined.TrekButton-regular:focus {
      border-color: $border-color-light;
      outline: none;
    }
  }

  &__nav-link {
    margin-bottom: grid-px(3.75);
    text-transform: uppercase;
    text-decoration: none;
    color: $color-gray-2;
    font-size: $font-size-standard;
    cursor: pointer;
    letter-spacing: 1px;
    font-weight: 500;

    &--active {
      color: $text-black;
    }

    &:last-child {
      margin-bottom: grid-px(0);
    }
  }
}
