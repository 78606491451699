@import '../../styles/index.scss';

.status-container {
  &__activator > * {
    cursor: pointer;
  }

  &__modal {
    .ant-modal {
      &-close-x {
        svg {
          width: $page-header-font-size;
          height: $page-header-font-size;
        }
      }

      &-header {
        border-bottom: none;
      }

      &-body {
        max-height: 260px;
        overflow-y: scroll;
        scrollbar-width: none;
        padding-top: grid-px(0.25);
        &::-webkit-scrollbar {
          display: none;
        }
      }

      &-footer {
        z-index: 1;
        text-align: center;
        border-top: none;
        box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2), 0px 5px 8px rgba(0, 0, 0, 0.14), 0px 1px 14px rgba(0, 0, 0, 0.12);
      }
    }
  }
}
