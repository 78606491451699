@import '../../styles/index.scss';

.account-menu {
  cursor: pointer;

  .TrekAccountMenu-container {
    box-shadow: inset 1px 0px 0px 0px $color-white-7;
    padding: grid-px(2);

    svg {
      path {
        fill: $color-white;
      }
    }

    .TrekUserIdentifier-avatar {
      background: $color-white-7;
    }

    .TrekUserIdentifier-userName {
      color: $color-white;
      text-transform: none;
      letter-spacing: 0;
    }
  }

  &--loading {
    color: $color-white;
    padding: 0 20px;
  }

  .TrekAccountMenu-dropDownContainer {
    width: 100%;

    .TrekProfileMenu-root {
      border-right: none;
      border-top: none;
      border: 1px solid $border-color-header;
      box-shadow: $raised-shadow-standard;
      width: 100%;

      .TrekProfileMenu-content {
        margin: auto;
        padding: grid-px(2);
      }

      .logout-btn {
        font-size: $font-size-standard;
      }
    }
  }
}
