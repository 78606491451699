@import '../../styles/index.scss';

$tab-title-border-radius: grid-px(1.6);
$tab-title-font-size: $font-size-standard;
$tab-title-font-weight: 600;

.task-tab-navigation {
  display: flex;
  flex-direction: column;

  .TrekTabNavigation-root {
    padding: 0 grid-px(3);
    position: relative;

    /* Using :after to make a "border" along the bottom of the .TrekTabNavigation-root. */
    /* This "border" can be covered by the selected tab's :after for the effect of making
    /* .TrekTabNavigationTab-selected seem "connected" to its content */
    &:after {
      position: absolute;
      border-bottom: 1px solid $border-color-light;
      bottom: 0;
      content: '';
      width: 100%;
      transform: translateX(-50%);
      left: 50%;
      z-index: auto;
    }
  }

  .TrekTabNavigationTab {
    @include button-reset;
    color: $text-dark;
    background: $border-color-header;
    box-sizing: content-box;
    border-right: 1px solid $border-color-light;
    border-top: 1px solid $border-color-light;
    margin: 0;
    padding: grid-px(1.5) grid-px(3);
    height: grid-px(2);
    position: relative;

    &:first-child {
      border-top-left-radius: $tab-title-border-radius;
      border-left: 1px solid $border-color-light;
    }

    &:last-child {
      border-top-right-radius: $tab-title-border-radius;
    }

    &-selected {
      background: $white-background;

      &:after {
        border-bottom: 1px solid $white-background;
        z-index: $task-tab-selected-z-index;
      }
    }

    .todo-item {
      font-size: $tab-title-font-size;
      font-weight: $tab-title-font-weight;
    }
  }

  & > &__task-tab {
    background: $white-background;
    border-left: 1px solid $border-color-light;
    border-right: 1px solid $border-color-light;
    border-bottom: 1px solid $border-color-light;
  }
}
