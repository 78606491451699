@import '../../styles/index.scss';

.loading-content {
  display: flex;
  align-items: center;
  color: $color-gray-3;

  &.main {
    display: table;
    margin: grid-px(6) auto;
  }

  &.main img {
    margin: 0 auto;
    height: grid-px(6);
  }

  .loading-text {
    vertical-align: super;
    margin-left: grid-px(0.5);
  }

  img {
    height: grid-px(3);
  }
}
