@import '../../styles/index';

.percentage-input {
  position: relative;
  width: grid-px(7.75);

  & * {
    font-size: 14px;
    color: $color-faux-black;
  }

  &__input {
    text-align: right;
    box-sizing: border-box;
    width: 100%;
    height: grid-px(4.25);
    border: 1px solid $border-color-light;
    outline: none;
    border-radius: 4px;
    padding-left: grid-px(1.5);
    padding-right: grid-px(2.3);
    min-width:  grid-px(7.5);
  }

  &__percentage_mark {
    position: absolute;
    top: grid-px(0.85);
    right: grid-px(1);
  }
}
