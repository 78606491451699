@import '../../../styles/index.scss';
@import '../../../common/Form/Form.scss';

.details {
  &__ad-name-input,
  &__ad-type-select {
    max-width: grid-px(35);
  }

  .dropdown {
    margin-left: grid-px(3);
    margin-right: grid-px(15);
  }

  &__type-rating-wrapper{
    display: flex;
    flex-direction: row;
  }
}
