@import '../../../../../styles/index.scss';

.vast-content-alert {
  margin-top: grid-px(3);

  ul {
    margin: 0;
    padding-left: grid-px(1.5);

    li {
      margin: grid-px(1) grid-px(1) 0;
    }
  }
}
