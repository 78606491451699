@import '../../styles/index.scss';

.asset-details {
  &__header {
    display: flex;
  }

  &__name {
    flex-grow: 1;
    margin: grid-px() 0 grid-px(0.5);
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    overflow-wrap: anywhere;
  }

  &__download {
    margin: grid-px(0.5) grid-px(0.5) 0 0;
  }

  &__metadata {
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
    color: $color-gray-2;
    margin: 0;
  }

  &__review-task-link {
    text-decoration: none;
    display: inline-flex;
    align-items: center;

    span {
      text-decoration: underline;
      color: $link-blue;
    }

    svg {
      margin-left: 2px;
      width: 8px;
      @include filter-blue-1;
    }
  }

  &__transcoding-status {
    display: flex;
  }

  &__transcoding-details {
    margin: grid-px(1.5) 0;
  }

  &__transcoding-status-pill {
    margin-left: 4px;
  }

  &__link-icon {
    width: 8px;
  }

  &__asset-tags {
    display: flex;
    align-items: center;
  }

  &__label {
    font-size: $font-size-standard;
    font-weight: $title-font-weight;
    margin-right: 8px;
  }

  &__tags {
    font-size: $font-size-small;
  }
}
