@import '../../../../../../../styles/index.scss';

.multiple-edit-third-step-header {
  &__summary {
    margin-bottom: grid-px(3);
  }

  &__description {
    font-weight: 400;
  }

  &__button-content-wrapper {
    display: flex;
    align-items: center;
    line-height: grid-px(2);

    .loading {
      margin-left: grid-px(0.5);
      height: grid-px(2);
    }
  }
}
