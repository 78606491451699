@import '../../../../styles/index';

.column-toggle {
  display: flex;
  align-items: center;
  padding: grid-px(0.5) 0;
  font-size: 12px;
  line-height: 18px;
  color: $text-dark;
  margin-bottom: grid-px(0.75);

  &--dragging, &:hover {
    background-color: $color-gray-6;
  }

  &--dragging {
    box-shadow: $drag-shadow;
    cursor: grabbing;
  }


  label {
    height: grid-px(2);
    margin-right: grid-px();
    border: none;
  }

  input {
    width: grid-px(2);
    height: grid-px(2);
    margin: 0;
  }


  &__icon-drag {
    height: grid-px(2);
    width: grid-px(2);

    margin-right: grid-px(0.75);

    path {
      fill: $color-gray-3;
    }
  }
}
