@import '../../../styles/index.scss';

.scheduling-section {
  margin-bottom: grid-px(5.25);

  > div:first-child {
    margin-bottom: grid-px(2.25);
  }

  > div {
    margin-bottom: grid-px(2);
  }
}
