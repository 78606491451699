@import '../../styles/index.scss';

.button {
  position: relative;

  &--loading {
    background: $color-black !important;
  }

  &__loading-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__text {

    &--loading {
      visibility: hidden;
      opacity: 0;
    }
  }
}
