@import '../../styles/index.scss';

@mixin alert-colors($alert-type, $border-color, $background-color) {
  &--#{$alert-type} {
    background-color: $background-color;
    border: 1px solid $border-color;
  }
}

.alert {
  left: 0;
  overflow: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: $alert-z-index;

  &__content {
    margin: grid-px(10) auto;
    width: 80%;
    display: flex;
    padding: grid-px(3);
    color: $color-faux-black;

    @include alert-colors('success', $color-green-base, $color-green-6);
    @include alert-colors('warning', $color-yellow-base, $color-yellow-6);
    @include alert-colors('information', $color-blue-base, $color-blue-6);
    @include alert-colors('error', $color-red-base, $color-red-6);
  }

  &__icon {
    flex: 0 0 grid-px(6);
  }

  &__text {
    flex: 1 1 auto;
    padding: grid-px(0.5) 0;
  }

  &__type {
    text-transform: uppercase;
    font-size: $font-size-standard;
    font-weight: 600;
  }

  &__header-text {
    margin-left: grid-px();
    font-size: $font-size-standard;
  }

  &__body-text {
    margin: grid-px(3) 0;
    font-size: 16px;
    line-height: 24px;
    word-break: break-word;
  }

  &__close-btn {
    flex: 0 0 grid-px(3);

    &:hover,
    &:focus {
      cursor: pointer;
    }
  }
}
