@import '../../styles/index.scss';

.TrekLabel-root {
  > span {
    > span {
      color: $color-gray-2;
    }
  }
}

.TrekLabel-error {
  > span {
    > span {
      color: $color-red-base;
    }
  }
}
