@import '../../../styles/index.scss';

.table-action-bar {
  height: grid-px(8);
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px 0 20px;
  background-color: $color-white;
  border-top: 1px solid $color-gray-3;

  // Buttons in the action bar get special treatment
  a {
    @include keyboard-focus-only;
    border: none;
    margin: 0;
    padding: 0;
  }

  button {
    border: none;
    margin: 0;
    padding: 0;
    color: $color-gray-2;
    
    &.TrekButton-disabled {
      color: $color-gray-3 !important;
    }

    .TrekButton-label {
      text-transform: capitalize;
      font-size: $font-size-standard;
      font-weight: 600;
      letter-spacing: normal;
    }

    &:hover {
      color: $color-faux-black;
    }
  }

  &__left-children {
    display: flex;
    align-items: center;

    & > *:not(:last-child) {
      margin-right: grid-px(3);
    }
  }

  &__right-children {
    display: flex;
    align-items: center;

    label {
      margin-right: grid-px(2);
      border: 1px solid $color-gray-3;
      border-radius: 4px;
    }

    .TrekSearchInput-icon{
      cursor: default;
    }
  }

  .toggle-switch {
    &__toggler {
      &--on {
        .ant-switch {
          background: $color-black;
        }
      }

      &--off {
        .ant-switch {
          background: rgb(0, 0, 0, 0.25);
        }
      }
    }
  }
}
