@import '../../../../../../../styles/index.scss';

.multiple-edit-confirm-row {
  position: relative;
  margin-bottom: grid-px(4);
  margin-left: grid-px(2);

  &__row-item {
    display: flex;
  }

  &__label {
    width: grid-px(19);
    text-align: left;
    min-height: grid-px(9);
  }

  &__value {
    flex: 2;
  }

  &__value-title {
    font-weight: 600;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    bottom: grid-px(-2);
    left: 0;
    width: 80%;
    height: 1px;
    margin-left: grid-px(-2);
    background-color: $border-color-header;
  }

  &:first-child {
    font-weight: 600;
  }

  &:last-child::after {
    bottom: grid-px(-7);
  }

  &__schedule-title {
    margin-bottom: grid-px();
    font-weight: 600;
  }

  &__info-alert {
    width: grid-px(45);

    .multiple-edit-confirm-row__value-title {
      font-size: grid-px(1.75);
    }
  }

  &__daypart-wrapper {
    display: flex;
    font-size: grid-px(1.5);
    font-weight: 500;
  }

  &__day {
    color: $color-grey-light;
    min-width: grid-px(5);
  }
}
