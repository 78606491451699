@import '../../../../styles/index';

.drawer-targeting-table-header {
  display: flex;
  align-items: center;
  padding-bottom: grid-px(4);

  div:first-child {
    flex-grow: 1;
    margin: 0;
  }
}