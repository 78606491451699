@import '../../styles/index.scss';

.details-table {
  tr {
    // Get rid of default row hover state on tables
    &:hover {
      background-color: unset;
    }

    td {
      font-weight: 400;
      font-size: $font-size-standard;
      line-height: 24px;
      // In order to align with the rest of the card, get rid of default 1px left padding on td
      padding: 0 0 grid-px(2) 0;
      strong {
        font-weight: 500;
      }

      &:not(first-of-type) {
        padding-right: grid-px(2);
      }
    }
  }
}
