@import '../../styles/index';

.targeting-table {
  font-size: $font-size-standard;
  width: 100%;

  td:not(.targeting-table__toggle-cell) {
    background-color: $white-background;
    line-height: 1.5715;
    padding: grid-px() grid-px() grid-px() grid-px(2);
    vertical-align: baseline;
  }

  &__header-cell {
    display: flex;

    .sort-arrow div {
      display: flex;
    }
  }

  &__expand-collapse-text {
    background: none;
    border: none;
    color: #1A71E5;
    cursor: pointer;
    font-size: $font-size-small;
    font-weight: $title-font-weight;
  }

  &__no-targeting {
    display: block;
    padding: grid-px(2) 0;
    text-align: center;
    font-size: $font-size-standard + 2;
    line-height: grid-px(3);
    color: $color-gray-2;
  }

  &__toggle {
    max-height: 100%;
    overflow-x: hidden;
  }

  &__clear {
    font-family: $font-default;
    font-size: $font-size-standard;
    font-weight: $title-font-weight;
    color: $color-blue-base;
    text-decoration: underline;
    cursor: pointer;
    border: none;
    background: transparent;
  }

  thead {
    tr {
      background-color: $color-gray-4;
      border-bottom: 1px solid $color-gray-1;
      border-top-left-radius: grid-px();
      border-top-right-radius: grid-px();
      color: $color-gray-1;

      th,
      td {
        font-weight: 600;
        padding: grid-px() grid-px() grid-px() grid-px(2);
        text-align: left;
      }
    }
  }

  table &__sub-row-table {
    display: block;
  }

  tbody &__sub-row-body {
    display: block;
    width: 100%;
    border-bottom: 1px solid $color-gray-4;

    .expand-row-link {
      background: none;
      border: none;
      color: #3611C8;
      cursor: pointer;
      word-break: normal;
    }

    > tr {
      margin-left: grid-px(4);
    }

    > tr > td {
      hyphens: auto;
      overflow-wrap: break-word;
      display: flex;
      align-items: center;
    }

    > tr > td:nth-child(3) {
      word-break: break-word;
    }

    > tr > td:last-of-type {
      padding: grid-px(1) grid-px(1);
    }
  }

  .toggle-section__title-wrapper {
    background-color: $color-gray-6;
    border-bottom: 1px solid $color-gray-4;
    border-top: 1px solid $color-gray-4;
    padding: grid-px(1.25);

    .toggle-section__title {
      color: $color-gray-2;
      font-size: $font-size-small;
      font-weight: 700;

      span {
        font-weight: normal;
      }
    }

    svg {
      height: $font-size-small;
    }
  }

  &__toggle-top-row {
    border-top: 1px solid transparent;

    svg.chevron {
      will-change: transform;
      transition: transform 150ms linear;
      cursor: pointer;
      position: absolute;
      left: grid-px(2);
      transform: translateY(-50%);
      top: 50%;
      height: 20px;
      width: 20px;

      path {
        fill: #3611C8;
      }
    }

    &--active {
      svg.chevron {
        transform: rotate(90deg);
        top: 35%
      }
    }
  }

  &__toggle-top-row-wrapper {
    display: block;
    align-items: center;
    position: relative;
    width: 100%;
  }

  &__toggle-row-children {
    tbody, td:not(.targeting-table__toggle-cell) {
      background-color: #F7F9FA;
    }

    tr td:nth-child(1),
    tr td:nth-child(2) {
      color: transparent;
      user-select: none;
    }
  }

  &__preset-display {
    align-items: center;
    display: flex;
    justify-content: flex-start;

    .flyout {
      /* background is transparent by default */
      background: $color-white;
      line-height: 1;
    }
  }

  &__preset-name {
    /* allows element to grow to fill up space */
    flex-grow: 1;
    /* allows element to shrink below its default width */
    min-width: 0;
    white-space: nowrap;
    @include text-overflow-ellipsis;
  }

  &__flyout-btn {
    @include button-reset;
    @include filter-blue-2;
  }
}
