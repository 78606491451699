@import '../../../../../styles/index.scss';

.vast-preview-body {
  margin: grid-px(4) grid-px(3);

  &__info {
    & > .TrekTextField-root {
      float: none;
    }
    & > .alert {
      margin-top: grid-px(2);
    }

    .submit-input {
      margin: 0;
    }
  }

  &__content {
    margin-top: grid-px(3);

    & > .TrekLabel-root {
      margin-top: grid-px(2);
    }

    p {
      overflow-wrap: break-word;
    }
  }

  &__message {
    display: block;
    font-size: $font-size-small;
    margin-top: grid-px(1);
  }

  .TrekSearchField-label {
    color: $color-faux-black !important;
  }
}
