@import '../../../styles/index';

.change-mod {
  position: absolute;
  top: -12px;
  right: 0;

  .TrekButton-root {
    margin-top: 0;
    margin-bottom: 0;
    height: grid-px(5);
    border: none;

    & span {
      text-transform: capitalize;
      font-weight: normal;
      font-size: $font-size-standard;
      line-height: 14px;
    }
  }

  .TrekButton-disabled {
    opacity: 0.5;
  }

  &__save-cancel-wrapper &__cancel-button {
    background-color: $color-gray-5;
    width: 72px;
    color: $color-gray-1;

    &:hover {
      background: $color-gray-4;
    }
  }

  &__save-cancel-wrapper &__save-button {
    background-color: $color-blue-base;
    width: 80px;

    &:hover {
      background: $color-blue-dark;
    }
    &:focus {
      outline-offset: 4px;
    }
  }

  &__edit-button {
    width: 80px;
  }

  * > .button--loading {
    background-color: $color-blue-base !important;
  }
}
