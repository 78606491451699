@import '../../../../styles/index.scss';

.pause-creative {
  &__grid {
    @include form-grid-display;
    grid-template-columns: grid-px(45) grid-px(35);
    grid-template-areas: 'left right';

    > div {
      @include form-grid-child;
    }

    &__left-column {
      grid-area: 'left';
    }

    &__right-column {
      grid-area: 'right';

      & > div {
        margin-bottom: grid-px(2);
      }
    }

    .color-picker {
      margin-top: grid-px(4);
    }

    .text-field-container {
      margin: 0;
    }
  }

  &__footer {
    text-align: left;
    border-top: 1px solid $color-gray-3;
  }
}
