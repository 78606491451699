@import '../../../../styles/index.scss';

.ias-campaign-line-item-id-field {
  margin-right: grid-px(3);

  &__helper-text {
    overflow-wrap: break-word;

    &--highlighted {
      color: $color-gray-1;
      font-weight: 600;
    }
  }

  &__extra-helper-text {
    padding-top: grid-px();
  }

  .TrekTextField-root {
    margin: grid-px(4) 0 0 0 !important;
  }
}
