.modal-popup {
    ul {
        max-height: 250px;
        overflow-y: auto;
    }

    .loading-content {
        display: flex;
        justify-content: center;
    }
}