@import '../../../styles/index.scss';

.clear-all-btn {
  position: relative;
  display: flex;
  align-items: center;
  font-size: grid-px(1.75);
  font-weight: 500;
  margin-left: grid-px(3);
  padding: grid-px() 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  color: $color-blue-dark-3;
  text-transform: capitalize;

  &__close-icon {
    margin-left: grid-px(0.5);

    path {
      fill: $color-blue-dark-3;
    }
  }
}
