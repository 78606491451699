@import '../../styles/index.scss';
@import '../../styles/constants.scss';

.pagenotfound-container {
  height: 100vh;
  background-image: url('https://httpcalabash-proxy.prod.hulu.com/scratch/jordan/tools-404.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.pagenotfound-text {
  margin-top: 0;
  text-align: center;
  color: $white-background;
}
