@import '../../styles/constants.scss';
@import '../../styles/colors.scss';

#common-table-container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.common-table__action-bar {
  height: 4em;
  display: flex;
  flex-shrink: 0;
  justify-content: space-between;
  align-items: center;
  padding: 0 1em;
  background-color: $table-background;
  border-top: 1px solid $table-border;
  border-right: 1px solid $table-border;
  border-left: 1px solid $table-border;

  .common-table__left-actions,
  .common-table__right-actions {
    display: flex;
    align-items: center;

    .common-table__link-text {
      font-weight: 600;
      font-size: 14px;
    }

    .common-table__link-container {
      cursor: pointer;
      color: #646f85;

      svg {
        filter: brightness(0) saturate(100%) invert(87%) sepia(14%) saturate(183%) hue-rotate(182deg) brightness(87%)
          contrast(89%);
      }
    }

    .common-table__link-container-disabled,
    .common-table__link-container-unsupported {
      color: rgba(39, 44, 53, 0.3);
      cursor: not-allowed;

      svg {
        filter: brightness(3) invert(1);
      }
    }
  }

  .common-table__right-actions label {
    border: 1px solid $table-border;
    border-radius: 4px;
  }

  .common-table__link-container,
  .common-table__link-container-disabled,
  .common-table__link-container-unsupported {
    display: flex;
    align-items: center;
    margin-right: 0.625em;
  }
}

.common-table__table-wrapper {
  overflow: auto;
}

.common-table__table {
  flex-grow: 1;
  overflow: auto;
  background-color: $table-background;
  border-collapse: separate;
  border-spacing: 0;
  counter-reset: row-number;
  overflow-y: hidden;
  position: relative;
  width: 100%;
  border: 1px solid $table-border;

  .header-cell,
  .header-cell-sorted {
    display: flex;
  }

  thead {
    tr {
      background: $table-background;
      position: relative;
      z-index: 5;
    }

    th[role='columnheader'] {
      background: $table-background;
      border: 0;
      border-bottom: 1px solid #dde0e6;
      color: #272c35;
      font-size: 0.875em;
      font-weight: 500;
      line-height: 2.5;
      outline: none;
      position: sticky;
      text-align: left;
      padding: 0.5em 1em 0;
      top: 0;
      white-space: nowrap;
      z-index: 5;
    }

    .sticky {
      position: sticky !important;
      left: 0;
      top: 0;
      z-index: 6 !important;
      background-color: $table-background !important;
    }
  }

  tbody {
    > div > tr > td {
      .controlled-checkbox__label-content {
        display: none !important;
      }
    }

    .sticky {
      position: sticky !important;
      left: 0;
      top: 0;
      z-index: 3 !important;
      background-color: $table-background !important;
    }

    tr[role='row'] {
      &.checked {
        background-color: #e7f1fd !important;
      }

      .controlled-checkbox {
        > input {
          display: none;
        }
      }

      .controlled-checkbox--is-checked {
        input {
          display: inline-block !important;
          margin: 0 0;
        }

        .controlled-checkbox__label-content {
          display: none !important;
        }

        tr {
          background-color: $color-blue-6;
        }
      }

      &:hover,
      &:focus-within {
        background: $color-gray-6;

        .controlled-checkbox {
          input {
            display: inline-block;
            outline: none;
            margin: 0 0;
          }

          &__label-content {
            display: none;
          }
        }
      }
    }

    td[role='cell'] {
      border-bottom: 1px solid #f0f1f5;
      white-space: nowrap;
      line-height: 1.5em;
      color: #272c35;

      &.not-editable {
        cursor: default;
        padding: 0.875em 1em;
      }

      &.selected:focus-within {
        box-shadow: inset 0 0 0 1px #176ee1;
        outline: none;
      }
    }
  }
}
