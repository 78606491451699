@import '../../styles/index.scss';

.targeting-dropdowns {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;

  &__category-dropdown {
    width: 50%;
    margin-right: grid-px(1.5);
  }

  &__category-dimension-wrapper {
    display: flex;
    margin-bottom: grid-px(2);
  }

  &__value-wrapper {
    width: 100%;
    margin-bottom: grid-px(3.375);
  }

  &__dimension-wrapper,
  &__category-dropdown {
    width: 50%;
  }

  &__dimension-wrapper {
    .dropdown {
      .dropdown-list__option {
        .controlled-checkbox {
          display: none;
        }
      }
    }
  }

  &__value-wrapper,
  &__dimension-wrapper,
  &__category-dropdown {
    .dropdown {
      width: 100%;

      .dropdown-button button.trek-dropdown,
      .dropdown-button button.trek-dropdown.TrekDropDown-formControlActive {
        width: -webkit-fill-available;
        max-width: 100%;
      }

      .dropdown-list__menu-list {
        padding: 0;
      }

      .dropdown-list__option {
        padding: grid-px(1.625) grid-px(3) grid-px(1.75);

        label {
          input {
            cursor: pointer;
          }
        }
      }
    }
  }

  &__action-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__clear {
    font-family: $font-default;
    font-size: $font-size-standard;
    font-weight: $title-font-weight;
    color: $color-blue-base;
    text-decoration: underline;
    cursor: pointer;
    border: none;
    background: transparent;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }

  & .include-btn {
    background-color: $color-brand-primary;
    color: $text-black;

    svg {
      @include filter-faux-black;
    }

    &:hover {
      background-color: $color-brand-primary;
    }
  }

  & .exclude-btn {
    background-color: $color-red-base;

    &:hover {
      background-color: $color-red-base;
    }
  }
}
