@import '../../../../../../../styles/index.scss';

$multiple-edit-tags_header_font_weight: 400;
$multiple-edit-tags_header_line_height: 18px;
$multiple-edit-tags_header_margin_top: 2px;

.multiple-edit-tags {
  position: relative;

  &__table {
    overflow-x: auto;

    &--not-expanded {
      .dropdown--ad-tag-event {
        .dropdown__container {
          right: 0px;
        }
      }
    }
  }

  .dropdown {
    position: unset;
  }

  .dropdown__container {
    width: fit-content;
  }

  &__header {
    color: $text-dark;
    font-size: $font-size-small;
    font-weight: $multiple-edit-tags_header_font_weight;
    line-height: $multiple-edit-tags_header_line_height;
    margin-top: $multiple-edit-tags_header_margin_top;
  }
}
