@import '../../../styles/index.scss';
@import '../../../styles/constants.scss';

.mstr-report {
  iframe {
    width: 100%;
    // stretch iframe to fill window, leaving space for header and footer
    height: $content-height;
  }
}
