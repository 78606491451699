@import '../../styles/index.scss';

.footer {
  background: $color-gray-6;
  display: flex;
  justify-content: flex-end;
  padding: 1rem;
  font-family: $font-default;

  & > span {
    color: rgba(0, 0, 0, 0.5);
  }
}
