@import '../../../../styles/index.scss';

.instream-dxc-builder {
  &__asset-tabs {
    .TrekTabNavigation-root {
      position: relative;
      display: flex;
      // this forces the tab nav to overlap with the currently selected tab content
      transform: translateY(1px);
    }

    .TrekTabNavigationTab-root {
      display: block;
      background-color: $color-gray-5;
      border: 1px solid $color-gray-3;
      border-bottom: none;
      box-sizing: border-box;
      color: $color-gray-2;
      font-size: $font-size-standard;
      font-weight: 600;
      line-height: 1;
      padding: grid-px() grid-px(2);
      text-align: left;
      width: 100%;
      margin: 0;

      &:first-child {
        border-left: none;
        margin: auto;
      }

      &:last-child {
        border-right: none;
        margin: auto;
      }

      &:hover {
        color: $color-gray-1;
      }

      // bottom border for tab
      &:after {
        border-bottom: 1px solid $color-gray-3;
        box-shadow: $raised-shadow-standard;
      }

      .rows-count {
        font-weight: normal;
        margin-right: grid-px(1);
      }
    }

    .TrekTabNavigationTab-selected {
      background-color: $white-background;
      box-shadow: $box-shadow-dark;
      color: $color-faux-black;

      // remove bottom border for selected tab
      &::after {
        border: none;
        box-shadow: none;
      }
    }
  }

  input:disabled {
    background-color: $white-background;
  }

  &__tab-counter {
    font-weight: normal;
  }

  &__tab-title {
    align-content: center;
  }

  &__creative-card {
    background-color: $color-gray-6;
    border: 1px solid $color-gray-3;
  }

  &__video-asset-list {
    margin: grid-px(4) grid-px(3);
  }

  &__preview-button {
    width: fit-content;
    height: 40px;
    align-self: center;
  }

  &__loader {
    width: 100%;
    justify-content: center;

    .loading-content {
      display: block;

      img {
        height: 36px;
      }
    }
  }
}
