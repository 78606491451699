@import "../../../../../styles/index.scss";

.option-header {
    &__icon-drag {
        cursor: grab;
        margin-right: grid-px(0.5);
        width: grid-px(2.5);

        path {
            fill: $color-gray-3;
        }
    }
}
