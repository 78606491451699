@import "../../styles/index.scss";

.todo-item {
  display: flex;
  align-items: center;
  font-size: 1em;
  margin-bottom: 0.5em;

  &__check-wrapper {
    display: inline-block;
    margin-left: 0.5em;
    width: 1.142em;
    height: 1.142em;

    > svg {
      width: grid-px(2.75);
      height: grid-px(2.75);
    }
  }

  &__text {
    height: 1em;
    margin-top: -0.125em;
    align-self: center;
    line-height: grid-px(3);
  }
}
