@import '../../../../../styles/index.scss';

$multiple-edit-drawer-margin: -11px;

.multiple-edit-drawer-title {
    color: $color-title-gray;
    font-size: $font-size-big;
    font-weight: $title-font-weight;
    line-height: $title-font-size;
    margin-left: $multiple-edit-drawer-margin;
}
