@import '../../../../../styles/index.scss';

.date-pacing {
  &__header {
    display: grid;
    grid-template-columns: 112px 117px 1fr;
    color: $color-black;
    text-transform: uppercase;
    font-size: $font-size-small;
    margin-bottom: grid-px();
    font-weight: 600;
  }

  &__list {
    display: grid;
    grid-template-columns: grid-px(11) grid-px(3) grid-px(11) 29px grid-px(7.5);
    row-gap: grid-px();
    font-size: $font-size-standard;
    line-height: 21px;
    color: $color-black;
  }
}
