@import '../../../../styles/index.scss';

.daypart-tabs {
  &__date-title {
    display: flex;
    margin-bottom: grid-px();
    align-items: center;

    h5 {
      margin: 0;
      color: $color-gray-2;
      font-size: 18px;
    }

    svg {
      margin-right: grid-px();

      path {
        fill: $color-gray-2;
      }
    }
  }

  &__segmented-control {
    @include segmented-control-nav;
    margin-bottom: grid-px(2.5);
  }

  &--readonly {
    pointer-events: none;
  }
}
