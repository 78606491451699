@import '../../styles/index.scss';

.date-filter {
  position: relative;
}


.modal-datepicker {
  position: absolute;
  top: 0;
  left: 220px;
  padding: 10px;
  box-shadow: $tooltip-shadow;
  background-color: $color-white;
  z-index: 1000;
  outline: none;

  .daterange__row {
    margin-left: 35px;
    margin-top: 0;
  }

  .datetime-input {
    padding: 5px;
  }


  label.controlled-checkbox {
    display: flex;

    span {
      margin-left: 3px;
    }
  }

  .datetime-input:focus-within {
    border: 1px solid black;
  }

  .container-checkbox-range-inputs {
    display: flex;

    .checkbox-container {
      display: flex;
      flex-direction: column;
    }
  }

  .TrekButton-disabled {
    color: $color-white;
    opacity: 0.6;
    pointer-events: auto;
  }

  .icon-close {
    text-align: end;
    cursor: pointer;
  }

  .error-date-message {
    color: $color-red-base;
  }
}
