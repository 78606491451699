@import '../../../styles/index.scss';
@import '../../../common/Form/Form.scss';

.scheduling {
  display: block;

  &__card-outer-wrapper {
    display: flex;
    flex-direction: column;
    margin: grid-px();
  }

  &__location-wrapper {
    display: flex;
    align-self: flex-end;
    flex-direction: row;
    align-items: center;

    .globe-icon {
      margin-right: grid-px(0.5);
    }
  }

  &__location-text {
    display: flex;
    color: $color-gray-2;
    font-size: 11px;
    line-height: 1.3;
    vertical-align: middle;
  }

  &__card-inner-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 grid-px(3) grid-px(3) grid-px(3);
  }

  &__date-title {
    display: flex;
    margin-bottom: grid-px();
    align-items: center;

    h5 {
      margin: 0;
      color: $color-gray-2;
      font-size: 18px;
    }

    svg {
      margin-right: grid-px();

      path {
        fill: $color-gray-2;
      }
    }
  }
}
