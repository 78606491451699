@import '../../styles/index.scss';

.toggle-switch {
  &__label {
    font-size: grid-px(1.5);
    font-weight: 500;
    line-height: grid-px(1.5);
    margin: grid-px(0.5) 0;
  }

  &__horizontal-label {
    margin-left: grid-px(1.5);
    color: $color-grey-light;
    font-size: grid-px(1.75);
    font-weight: 600;
    vertical-align: middle;
  }

  &__toggle-switch-wrapper {
    display: inline-block;
  }

  &__toggler {
    padding: grid-px(1) 0;

    &--disabled {
      .ant-switch-handle {
        opacity: 0.6;
      }
    }

    button {
      box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.25);
    }

    .ant-switch {
      width: grid-px(6);
      height: grid-px(3);
    }

    .ant-switch-handle {
      top: grid-px(0.5);
      left: grid-px(0.5);
      width: grid-px(2);
      height: grid-px(2);
    }

    .ant-switch-checked {
      .ant-switch-handle {
        left: calc(100% - 20px);
      }
    }

    &--off {
      .ant-switch {
        background: $color-red-base;
      }

      .ant-switch-handle {
        color: $white-background;
      }

      svg {
        height: 11px;
        width: 11px;
        left: 1px;
        top: 1px;
        position: relative;
        @include filter-white;
      }

      & .ant-switch-disabled {
        opacity: 1;
      }

      &.toggle-switch__toggler--disabled {
        .ant-switch {
          background: $color-red-5;
        }

        svg {
          @include filter-red-2;
        }
      }
    }

    &--on {
      .ant-switch {
        background: $color-green-base;
      }

      svg {
        height: 12px;
        width: 12px;
        left: -2px;
        top: 2px;
        position: relative;
        @include filter-white;
      }

      & .ant-switch-disabled {
        opacity: 1;
      }

      &.toggle-switch__toggler--disabled {
        .ant-switch {
          background: $color-green-5;
        }
      }
    }
  }
}
