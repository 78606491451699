@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/constants.scss';
@import '../Form.scss';

.asset-importer-field {
  margin: $form-element-margin;

  // Position remove asset button
  position: relative;

  .remove-asset-button {
    position: absolute;
    top: grid-px(3.75);
    right: grid-px();
    @include filter-gray-4;
    opacity: 0.7;

    &:hover {
      cursor: pointer;
    }

    &:disabled {
      cursor: not-allowed;
      pointer-events: auto;
      @include filter-gray-disabled;
    }
  }

  .TrekSearchResultsItem-root {
    p {
      display: block;
    }
  }

  &__import-wrapper {
    max-width: 100%;
    max-height: 100%;
    background-color: $color-gray-6;
    border: 1px dashed $color-gray-2;
  }

  &__back-to-uploader {
    @include button-reset;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: grid-px(5) auto 0 auto;

    span {
      font-size: $font-size-standard;
      color: $color-gray-1;
      margin-left: grid-px(1.5);
    }
  }

  &__search.entity-search {
    margin: grid-px(3) grid-px(5);
  }

  &__vast-input {
    padding: 0 grid-px(8);
  }

  & .validation-error {
    border-color: $color-red-base;
  }
}
