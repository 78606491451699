@import '../../styles/index.scss';

.default-page-header {
  background-color: $color-grey-dark;
  color: $color-white;
  padding: grid-px() 0px;
  display: flex;
  position: sticky;
  top: $header-height;

  &__back-icon {
    background: none;
    border: none;
    cursor: pointer;
    margin: 14px grid-px(2);
    padding: 0px;

    @include keyboard-focus-only;
  }

  svg {
    height: 27px;
    width: 27px;
    margin: -4.5px;
  }

  path {
    fill: $color-white;
  }

  &__back-icon:hover &__icon path {
    fill: $color-white-4;
  }

  & > header {
    border-left: 1px solid $color-gray-2;
    box-sizing: border-box;
    height: grid-px(6);
    padding: 2px 0 2px grid-px(2);

    h1,
    h2 {
      color: $color-white;
    }
  }

  &__campaign-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin: 0px auto 3px;
  }

  &__line-item-name {
    font-size: $font-size-standard;
    font-weight: normal;
    line-height: 21px;
    margin: 0px auto;
  }
}
