@import '../../../styles/index.scss';

.editable-frequency-cap {
  font-size: $font-size-standard;
  // must be relative so that modal is positioned correctly
  position: relative;
  height: $cell-height;
  width: 100%;

  .dropdown--dropdown {
    .TrekDropDown-root {
      .TrekDropDown-formControl {
        border: 1px solid $color-gray-3;
        border-radius: 4px;
      }
    }

    .dropdown-button {
      label {
        display: block;
        margin: 0 0 grid-px();
      }
    }

    .dropdown__container {
      bottom: grid-px(-0.5);
    }
  }

  &__display {
    font-size: $font-size-standard;
    height: $cell-height;
    line-height: $cell-height;
    @include text-overflow-ellipsis;

    &--multiple-fc {
      padding-right: 36px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  &__rendered-value {
    line-height: grid-px(5);
    vertical-align: middle;
    padding-left: grid-px(2);
  }

  & .editable-cell-modal__wrapper {
    height: initial;
    box-shadow: $raised-shadow-standard;
  }

  & .editable-cell-modal__content {
    padding: 12px;
    background: $color-white;
    border-bottom: 1px solid $border-color-light;
    z-index: $modal-z-index;
  }

  // Modal footer
  & .editable-cell-modal__buttons {
    z-index: $editable-dropdown-menu-z-index;
    background: $color-white;
    padding: grid-px();
    margin: 0;

    & .TrekAlert-error {
      margin-right: auto;
    }

    & button {
      box-shadow: none;
      cursor: pointer;
    }
  }
}

// TODO(AX-2038): remove dependency on .TrekDropDown-popUpContainer
// TrekDropDown-popUpContainer is appended to page <body> and is not a child of the
// .editable-frequency-cap component
.TrekDropDown-popUpContainer {
  z-index: $modal-z-index + 1 !important;
}
