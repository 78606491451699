@import '../../../styles/index';

.column-controls {
  overflow: hidden;

  &__header {
    border: 1px solid $color-gray-4;

    label {
      font-size: 12px;
      font-weight: 400;
      box-sizing: border-box;
      max-height: grid-px(4);
      width: grid-px(32);

      .TrekSearchInput-formControl {
        padding: grid-px(0.875) grid-px(1.5) grid-px(0.875) grid-px(1.5);
      }
    }

    svg {
      width: grid-px(1.75);
      height: grid-px(1.75);
    }
  }

  &__header {
    padding: grid-px(1.5) grid-px(1.5) grid-px() grid-px(1.5);
  }

  &__toggle-block {
    padding: grid-px(1.125) grid-px(1.5) 0 grid-px(1.5);
    max-height: grid-px(34.625);
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: grid-px(0.5);
    }

    &::-webkit-scrollbar-thumb {
      background-color: #040405;
    }
  }

  &__control-buttons {
    display: flex;
    justify-content: space-between;
    margin-top: grid-px(1.5);
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;

    span {
      cursor: pointer;
    }
  }

  &__toggle-all {
    color: $color-blue-base;

    &:hover {
      color: $color-blue-dark-1;
    }
  }

  &__restore {
    color: $color-gray-2;

    &:hover {
      color: $color-red-input-error
    }
  }

  &__not-found {
    font-weight: 500;
    padding: grid-px(1.25);
    text-align: center;
    color: $color-gray-2;
  }
}
