/* default fonts suggested by Project Margaret */
$font-default: 'Graphik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell',
  'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$font-code: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;

@font-face {
  font-family: 'Graphik';
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-SuperItalic-Web.eot');
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-SuperItalic-Web.eot?#iefix')
      format('embedded-opentype'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-SuperItalic-Web.woff2') format('woff2'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-SuperItalic-Web.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Super-Web.eot');
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Super-Web.eot?#iefix') format('embedded-opentype'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Super-Web.woff2') format('woff2'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Super-Web.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-BlackItalic-Web.eot');
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-BlackItalic-Web.eot?#iefix')
      format('embedded-opentype'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-BlackItalic-Web.woff2') format('woff2'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-BlackItalic-Web.woff') format('woff');
  font-weight: 800;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Black-Web.eot');
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Black-Web.eot?#iefix') format('embedded-opentype'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Black-Web.woff2') format('woff2'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Black-Web.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-BoldItalic-Web.eot');
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-BoldItalic-Web.eot?#iefix') format('embedded-opentype'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-BoldItalic-Web.woff2') format('woff2'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-BoldItalic-Web.woff') format('woff');
  font-weight: 700;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Bold-Web.eot');
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Bold-Web.eot?#iefix') format('embedded-opentype'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Bold-Web.woff2') format('woff2'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Bold-Web.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-SemiboldItalic-Web.eot');
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-SemiboldItalic-Web.eot?#iefix')
      format('embedded-opentype'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-SemiboldItalic-Web.woff2') format('woff2'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-SemiboldItalic-Web.woff') format('woff');
  font-weight: 600;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Semibold-Web.eot');
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Semibold-Web.eot?#iefix') format('embedded-opentype'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Semibold-Web.woff2') format('woff2'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Semibold-Web.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-MediumItalic-Web.eot');
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-MediumItalic-Web.eot?#iefix')
      format('embedded-opentype'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-MediumItalic-Web.woff2') format('woff2'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-MediumItalic-Web.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Medium-Web.eot');
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Medium-Web.eot?#iefix') format('embedded-opentype'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Medium-Web.woff2') format('woff2'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Medium-Web.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-RegularItalic-Web.eot');
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-RegularItalic-Web.eot?#iefix')
      format('embedded-opentype'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-RegularItalic-Web.woff2') format('woff2'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-RegularItalic-Web.woff') format('woff');
  font-weight: 400;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Regular-Web.eot');
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Regular-Web.eot?#iefix') format('embedded-opentype'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Regular-Web.woff2') format('woff2'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Regular-Web.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-LightItalic-Web.eot');
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-LightItalic-Web.eot?#iefix')
      format('embedded-opentype'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-LightItalic-Web.woff2') format('woff2'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-LightItalic-Web.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Light-Web.eot');
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Light-Web.eot?#iefix') format('embedded-opentype'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Light-Web.woff2') format('woff2'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Light-Web.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-ExtralightItalic-Web.eot');
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-ExtralightItalic-Web.eot?#iefix')
      format('embedded-opentype'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-ExtralightItalic-Web.woff2') format('woff2'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-ExtralightItalic-Web.woff') format('woff');
  font-weight: 200;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Extralight-Web.eot');
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Extralight-Web.eot?#iefix') format('embedded-opentype'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Extralight-Web.woff2') format('woff2'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Extralight-Web.woff') format('woff');
  font-weight: 200;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-ThinItalic-Web.eot');
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-ThinItalic-Web.eot?#iefix') format('embedded-opentype'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-ThinItalic-Web.woff2') format('woff2'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-ThinItalic-Web.woff') format('woff');
  font-weight: 100;
  font-style: italic;
  font-stretch: normal;
}

@font-face {
  font-family: 'Graphik';
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Thin-Web.eot');
  src: url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Thin-Web.eot?#iefix') format('embedded-opentype'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Thin-Web.woff2') format('woff2'),
    url('https://assetshuluimcom-a.akamaihd.net/FONTS/Graphik-Thin-Web.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
}
