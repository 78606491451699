@import '../../../styles/index';

.change-log-table {
  width: 100%;
  font-size: $font-size-small;

  tbody {
    tr td:nth-child(2) {
      padding-right: 0;
    }
    tr td:nth-child(3) {
      padding-left: 0;
    }
  }

  td {
    padding: grid-px();
    border-bottom: 1px solid $color-gray-4;
    vertical-align: top;
    background-color: $white-background;
    &.line-through {
      text-decoration: line-through;
    }
  }

  &__field {
    width: grid-px(16);
    word-wrap: break-word;
  }

  th:not(.change-log-table__field),
  td:not(.change-log-table__field) {
    width: 40%;
    word-wrap: break-word;
  }

  thead {
    tr {
      background-color: $color-gray-6;
      color: $color-gray-2;

      th,
      td {
        text-align: left;
        padding: grid-px();
      }
    }
  }
}
