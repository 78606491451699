@import '../../../../../../../styles/index.scss';
@import '../../MultipleEditDrawerContent.scss';

.multiple-edit-drawer-content-third-step {
    padding-left: $multiple-edit-drawer-content-step-left-padding;

    &__status-table-wrapper {
        margin: grid-px(9) 0 grid-px(13) 0;
    }

    .multiple-edit-drawer-content-header {
        align-items: flex-start;
    }

    .multiple-edit-drawer-content-table thead th:first-of-type {
        width: inherit;
    }

    .multiple-edit-confirm-row:first-of-type {
        .multiple-edit-confirm-row__label {
            min-height: 0;
        }
    }
}
