@import '../../../styles/colors.scss';
@import '../../../styles/constants.scss';
@import '../../../styles/mixins.scss';
@import '../../../common/Form/Form.scss';

.metadata {
  .card {
    margin-bottom: grid-px(19);
  }

  .frequency-cap {
    margin: $form-element-margin;

    &__header {
      display: flex;
      margin-bottom: 18px;
      svg {
        @include filter-gray-2;
      }

      > h5 {
        margin: 0 0 0 grid-px();
        color: $color-gray-2;
        font-size: 18px;
        font-weight: 700;
        line-height: 1.33;
      }
    }

    &__segmented-control {
      @include segmented-control-nav;
      margin-bottom: 20px;
    }

    .dropdown-list__menu {
      margin-top: 8px;
    }
  }

  .viewability-vendor {
    display: flex;
    justify-content: space-between;

    .text-field-container {
      .TrekTextField-root {
        margin: grid-px(4) grid-px(3);
      }
    }

    & .dropdown {
      width: 280px;
    }
  }

  &--readonly {
    pointer-events: none;
  }
}
