@import '../../../../styles/index.scss';

.basic-select-option {
  padding: grid-px(1);
  border-bottom: $color-gray-5;
  cursor: pointer;

  &:hover {
    background-color: $color-gray-6;
  }

  &__selected {
    background-color: $color-blue-6;
  }
}
