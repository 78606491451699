@import '../../../../styles/index.scss';

.duplicate-ad-form {

  &__title {
    font-weight: normal;
    font-size: $title-font-size;
    margin-top: grid-px(-1.25);
    margin-bottom: grid-px(3);
  }
}

