@import '../../../../../styles/constants.scss';
@import '../../../../../styles/colors.scss';

.creative-footer {
  hr {
    color: $color-gray-3;
  }

  &__split-info-wrapper {
    display: flex;
    align-items: center;
  }

  &__content {
    margin: grid-px(3);
    flex-grow: 1;

    &--interaction {
      text-align: right;

      .tooltip__wrapper {
        display: inline-block;
      }

      .tooltip__container > button {
        margin: 0
      }
    }

    &--information {
      max-width: 55%;
    }
  }
}
