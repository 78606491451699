@import "../../styles/index.scss";

.default-form-section-wrapper {
  &__title {
    font-size: grid-px(2.25);
    line-height: grid-px(3);
  }

  &__loader {
    display: flex;
    justify-content: center;
    padding: grid-px(5);

    .loading-content img {
      height: grid-px(6);
    }
  }
}
