@import '../Form';

$submit-input-max-width: 664px;
//This style is used for removing the not displayed 'Magnifying glass' button in searchField
$input-padding-left: 12px;

.svg-common-styles {
  position: relative;
  float: right;
  left: grid-px(-1.25);
}

.submit-input {
  overflow: hidden;
  margin: $form-element-margin;

  .TrekSearchField-root {
    max-width: $submit-input-max-width;
  }

  .TrekSearchField-searchIcon {
    display: none;
  }

  .TrekSearchField-container {
    margin-bottom: grid-px();
  }

  .TrekSearchField-formControl {
    padding-left: $input-padding-left;

    &:focus {
      padding-left: $input-padding-left;
    }

    &:hover {
      padding-left: $input-padding-left;
    }
  }

  .TrekSearchField-submitButton {
    outline: none;
  }

  &__clear-button {
    cursor: pointer;
  }

  &__isLoading {
    max-width: $submit-input-max-width;

    .loading-content {
      color: $color-gray-3;
    }

    .loading-text {
      vertical-align: super;
      margin-left: grid-px(0.5);
    }

    img {
      height: grid-px(3);
    }

    .TrekSearchField-label {
      color: $color-gray-3;
    }

    .TrekSearchField-formControl {
      color: $color-gray-3;
    }

    .TrekSearchField-submitButton {
      background: $color-gray-3;
    }

    .TrekSearchField-submitButtonIcon {
      mask: url(Eclipse.svg) no-repeat center / contain;
    }
  }

  &__inputSubmitted,
  &__inputDisabled {
    max-width: $submit-input-max-width;

    .TrekSearchField-submitButton {
      display: none;
    }

    svg {
      @extend .svg-common-styles;
      top: grid-px(-5.125);
    }
  }

  &__customClearButtonPosition {
    svg {
      @extend .svg-common-styles;
      top: grid-px(-6.125);
    }
  }
}
