@import "../../../../../styles/index.scss";

.layout {
  display: flex;
  width: 100%;
  gap: grid-px(6.25);

  &__left-side,
  &__right-side {
    flex: 0 1 50%;
    max-width: 50%;
  }

  &--wrapped {
    display: block;

    .layout {
      &__left-side,
      &__right-side {
        max-width: 100%;
      }
    }
  }
}
