@import '../../../styles/index.scss';

.customize-columns {
  position: relative;
  margin-right: grid-px(3.375);
  color: $color-gray-2;
  font-size: 14px;
  line-height: 1.9;
  height: grid-px(2.5);
  cursor: pointer;

  &__settings path {
    fill: $color-gray-3;
  }

  &--active, &:hover {
    color: $color-black;

    .customize-columns__settings path {
      fill: $color-black;
    }
  }

  &__toggle-block {
    display: flex;
  }

  &__text {
    display: inline-block;
    margin-left: grid-px(0.75);
    white-space: nowrap;
    font-weight: 500;
  }

  &__tooltip {
    position: absolute;
    top: 24px;
    right: 0;
    width: grid-px(35);
    box-shadow: $tooltip-shadow;
    background-color: $color-white;
    z-index: 1000;
  }
}
