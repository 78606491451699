@import '../../styles/colors.scss';

@mixin pill-colors($color-name, $color, $color-bg, $color-border) {
  &--#{$color-name} {
    color: $color;
    background-color: $color-bg;
    border: 1px solid $color-border;
  }
}

.pill {
  // Font
  font-size: 10px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 12px;
  letter-spacing: 1px;

  // Shape
  padding: 4px 12px;
  border-radius: 14px;

  // Color
  @include pill-colors('gray', $color-gray-1, $color-gray-4, $color-black-7);
  @include pill-colors('red', $color-red-dark, $color-red-6, $color-red-3);
  @include pill-colors('blue', $color-blue-dark, $color-blue-6, $color-blue-3);
  @include pill-colors('green', $color-green-dark, $color-green-6, $color-green-3);
  @include pill-colors('yellow', $color-yellow-1, $color-yellow-6,  $color-yellow-3);
  @include pill-colors('yellow-dark', $color-yellow-dark, $color-yellow-6,  $color-yellow-3);
  @include pill-colors('orange', $color-orange-1, $color-orange-6,  $color-orange-3);
}
