@import '../../../../../styles/index.scss';

.selected-filters-chips {
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: grid-px(3);
  flex-wrap: wrap;

  &__clear-all-btn {
    &::before {
      content: '';
      width: 0;
      height: 100%;
      position: absolute;
      border: 1px solid $color-black;
      top: 0;
      left: -24%;
    }
  }
}
