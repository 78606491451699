@import '../../../styles/index.scss';

.remove-entity-button {
  .modal-popup .ant-modal-footer .ant-btn {
    font-size: 12px;
    width: grid-px(27);
  }

  .modal-creative-name {
    font-weight: bold;
  }
}