@import '../../../../../../styles/index.scss';

.multiple-edit-drawer-content-card {
  &__title-wrapper {
    margin-bottom: grid-px(1.5);

    input {
      width: grid-px(2.25);
      height: grid-px(2.25);
      vertical-align: text-bottom;
    }
  }

  &__title {
    margin-left: grid-px(1);
    font-size: grid-px(2.25);
    line-height: grid-px(3);
  }

  &__error {
    margin-left: grid-px(1);
    font-size: grid-px(2);
    line-height: grid-px(3);
    color: red;
  }

  &__content {
    padding: grid-px(1.75);
    position: relative;
  }

  &__overlay {
    background: rgba(0, 0, 0, 0.15);
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 10;
    top: 0;
    left: 0;
  }
}
