@import '../../styles/colors.scss';
@import '../../styles/constants.scss';
@import '../../common/Form/Form.scss';
@import '../../styles/mixins.scss';

.asset-uploader {
  &__dropzone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    // The dropzone occupies 100% of the containing div
    // The containing div enforces the aspect ratio of the asset format being uploaded
    height: 100%;
    width: 100%;

    &--hovering {
      background-color: $color-gray-5;
    }
  }

  &__upload-message {
    padding: grid-px(2);

    strong {
      font-weight: 500;
    }
  }

  &__progress-bar.ant-progress {
    width: grid-px(42);
  }

  &__progress-details {
    width: grid-px(42);
    display: flex;
    justify-content: space-between;

    p {
      color: $color-gray-2;
    }
  }

  &__cancel-btn.TrekButton-root {
    padding: 0;
    margin: 0;
    border: none;
  }

  &__select-container {
    display: flex;
    justify-content: center;
    width: grid-px(50);
    align-items: flex-end;
    text-align: center;
    margin-top: 4px;

    > button {
      margin: 0 grid-px(2);
    }
  }

  &__head-info {
    padding-top: grid-px(3);
  }

  &__info {
    font-size: 11px;
    padding-top: grid-px(3);
    height: grid-px(15);
  }

  &__file-size-info{
    text-align: end;
  }

  &__sub-info {
    display: flex;
    justify-content: space-between;

    padding-top: grid-px(1.5);
  }

  &__select-button {
    @include button-reset;
    cursor: pointer;

    svg {
      @include filter-gray-2;
    }

    span {
      margin: grid-px() 0 grid-px(2) 0;
      color: $color-gray-1;
      display: block;
    }
  }
}
