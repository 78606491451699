@import '../../styles/index.scss';

.frequency-caps {
  display: block;

  .dropdown-button {
    display: flex;
    flex-direction: column;

    label {
      margin: 0px 0 grid-px() 0;
    }

    button.trek-dropdown.TrekDropDown-formControl {
      > svg {
        display: block;
      }
    }

    button.trek-dropdown.TrekDropDown-formControl:focus {
      outline: 2px auto $color-faux-black;
    }

    &:hover button.trek-dropdown.TrekDropDown-formControl {
      border-width: 2px;
      border-color: $color-gray-1;
    }

    .TrekDropDown-formControl.TrekDropDown-formControlActive {
      border-width: 2px;
      border-color: $color-faux-black;
    }

    span.TrekDropDownItem-label {
      font-size: $font-size-standard;
    }
  }

  .dropdown__container {
    width: 100%;
    bottom: 8px;

    .dropdown-list__menu {
      border: none;
    }

    .dropdown-list__menu-list {
      padding: 0;
    }

    .dropdown-list__option {
      padding: (grid-px() + 5) grid-px(3);
      font-size: $font-size-standard;
    }

    .dropdown-button__dropdown-child {
      font-size: $font-size-standard;
    }
  }

  &__fc-row {
    display: flex;
    align-items: center;
    margin-bottom: grid-px();

    &--empty {
      justify-content: space-between;

      & p,
      .row-btns {
        margin: 0;
      }
    }

    & .row-btns {
      & button {
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 0 0 0 4px;

        &:last-of-type {
          margin: 0 0 0 4px;
        }

        & svg {
          cursor: pointer;
          width: 20px;
          @include filter-gray-2;
        }

        &:disabled svg {
          cursor: not-allowed;
          @include filter-gray-3;
        }
      }
    }

    & .input-divider {
      font-size: 20px;
      padding: 0 4px;
      margin: 0;
    }

    & .input-divider,
    .row-btns {
      display: flex;
      height: 100%;
      align-items: center;
    }

    &:first-child {
      & .input-divider,
      .row-btns {
        margin-top: grid-px(2);
      }
    }
  }

  &__fc-row:not(:first-of-type) {
    & .TrekTextField-root,
    .TrekDropDown-root {
      & label {
        display: none;
      }
    }
  }

  .TrekTextField-root.TrekTextField-root {
    font-size: $font-size-standard;
    padding-top: 0;
    margin: 0 0 0 0;
    flex-basis: 25%;
    max-width: 50px;

    > input {
      width: 44px;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  .TrekDropDown-root {
    height: inherit;
    flex-basis: 50%;
  }

  .TrekTextField-root.TrekTextField-root,
  .TrekDropDown-root {
    display: flex;
    flex-direction: column;
    & label {
      margin: 0 0 grid-px() 0;
    }
  }

  .frequency-caps__item {
    &.dropdown {
      max-width: 108px;
      margin-left: 8px;
    }
  }
}

ul.TrekDropDown-popUp {
  li {
    width: unset; // Prevent overflow from default Trek style

    &--none {
      display: none;
    }
  }
}

.TrekTextField-labelDisabled {
  color: inherit !important;
}
