@import '../../../../../../../styles/index.scss';

.frequency-multi-edit-section{
  &__header {
    margin-top: 5px;
    margin-bottom: 15px;
    line-height: 18px;
    font-size: $font-size-small;
    font-weight: $font-weight-normal;
    color: $text-dark;
  }
}
