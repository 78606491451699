@import '../../../styles/index.scss';
@import '../../../common/Form/Form.scss';


.ads-page {
  height: $content-height;
  background-color: $page-background;

  &__body {
    display: flex;
    justify-content: space-between;
  }

  &__scroll-element {
    padding-top: grid-px(2);
  }

  .card {
    width: 800px;
  }
}
