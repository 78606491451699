@import '../../../../styles/mixins.scss';
@import '../../../../common/Form/Form.scss';

.creative-builder--interactive {
  .select {
    max-width: grid-px(35);
  }

  .vast-preview {
    margin: $form-element-margin;
  }

  .dropdown {
    margin-left: grid-px(3.125);
    overflow-wrap: break-word;

    width: 50%;

    .dropdown-list__menu-list {
      max-height: grid-px(25);
      overflow-y: auto;
    }
    .dropdown-list__option {
      overflow-x: hidden;
    }
  }

  .ingest {
    text-align: right;

    padding: grid-px(2) grid-px(4);
    border-top: grid-px(0.125) solid $color-gray-3;
  }
}
