@import '../../styles/index.scss';

nav.header {
  width: 100vw;
  z-index: $header-z-index;

  .TrekNavBar-content {
    overflow-x: auto;
    width: 100%;
    overflow-y: hidden;
    background-color: $color-faux-black;
    border-bottom: none;
    padding: 0;

    .BarItem-Logos {
      padding-left: 2 * $default-padding;

      svg {
        color: $color-brand-primary;
      }
    }

    .TrekNavBar-appName {
      background-color: $color-white;
      border-radius: 2px;
      color: $color-faux-black;
      padding: 2px grid-px();
    }

    .TrekNavBarItem-root {
      box-shadow: none;
    }
  }

  //menu
  .header__menu {
    display: flex;
    justify-content: center;
    width: 100%;

    a {
      align-items: center;
      border-radius: $border-radius-standard;
      color: $color-white;
      display: grid;
      font-size: $font-size-small;
      font-weight: 600;
      height: 32px;
      letter-spacing: 1px;
      margin: 0 grid-px(1);
      padding: 0 grid-px(2);
      text-decoration: none;
      text-transform: uppercase;

      &.active {
        background-color: $color-white-7;
      }

      &:hover {
        background-color: $color-white-5;
      }
    }
  }

  .header__menu-item {
    margin: 0 grid-px(1);
  }

  .basic-select-button {
    &:hover {
      background-color: $color-white-5;
    }

    &__active {
      background-color: $color-white-7;
    }
  }
}

.custom_user_modal {
  position: absolute;
  z-index: 999999;
  top: 63px;
  right: 0;
  height: grid-px(9.75);
  color: $color-faux-black;
  background-color: $color-white;
  font-size: 14px;
  border: 1px solid #dde0e6;
  box-shadow: $tooltip-shadow;
  cursor: pointer;
  outline: none;
  
  &__logout {
    text-align: center;
    padding: grid-px(1);

    &:hover {
      background-color: $color-gray-5;
    }
  }

  &__link {
    display: block;
    text-align: center;
    padding: grid-px(1);
    color: $color-faux-black;

    &:hover {
      background-color: $color-gray-5;
      color: $color-faux-black;
    }
  }
}

.header__navigation-tooltip {
  opacity: 1 !important;
  padding: 0 !important;

  .rc-tooltip-inner {
    border-radius: grid-px(1);
  }
}
