@import '../../styles/index.scss';

.datetime-input {
  display: inline;
  border-radius: 5px;
  border: 2px solid $color-faux-black;
  font-family: $font-default;
  font-size: $font-size-standard;
  line-height: 1;
  margin-left: -1px;

  input {
    background: none;
    border: none;
    font-family: $font-default;
    font-size: $font-size-standard;
    line-height: 1;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    max-height: $font-size-standard;
    outline: none;
    padding: 0px;
    text-transform: uppercase;

    // The mocks did not include the calendar and clock widgets that are embedded in "date" and "time" type input elements,
    // so these rules were added to remove the widgets in Webkit browsers. Firefox and IE/Edge do not have pseudo-selectors
    // for the calendar/clock widgets so Firefox and IE/Edge users will see the widgets (i.e. will not match the mocks).
    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      appearance: none;
      -webkit-appearance: none;
    }

    &::-webkit-datetime-edit-fields-wrapper {
      display: flex;
      justify-content: center;
    }

    &::-webkit-datetime-edit-hour-field {
      margin-left: grid-px(1);
    }
  }

  &__datetime-input {
    max-width: grid-px(25);
    margin-left: 0px;
  }

  &__date-input {
    max-width: grid-px(12);
    margin-left: 0px;
  }

  &__time-input {
    max-width: grid-px(12);
    margin-left: grid-px();

    &--minuteless::-webkit-datetime-edit-minute-field {
      display: none;
    }

    &--minuteless::-webkit-datetime-edit-text {
      display: none;
    }
  }

  &--disabled {
    color: $color-gray-2;
    background-color: $color-gray-5;
  }

  &--with-time {
    padding: grid-px(1.375) grid-px(0.5);
  }

  &--without-time {
    padding: grid-px(1.375) grid-px(2.5);
  }
}
