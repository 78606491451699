@import '../../styles/index.scss';

$drawer-normal-width: 600px;
$drawer-large-width: 50%;
$drawer-slide-transition: right 0.5s ease;
$drawer-expand-transition: width 0.3s ease;

.drawer {
  position: fixed;
  background: $color-white;
  height: 100%;
  box-shadow: $raised-shadow-standard;
  width: $drawer-normal-width;
  top: 0px;
  right: (-1 * $drawer-normal-width) - 50px;
  transition: $drawer-slide-transition , $drawer-expand-transition;
  -moz-transition: $drawer-slide-transition , $drawer-expand-transition;
  -webkit-transition: $drawer-slide-transition , $drawer-expand-transition;
  z-index: $drawer-z-index;

  &--large {
    width: $drawer-large-width;
    right: (-1 * $drawer-large-width) - 10%;
  }

  &--opened {
    right: 0px;
  }

  &--expanded {
    width: 100%;
  }

  & > header {
    display: flex;
    height: grid-px(7);
    justify-content: flex-end;
    padding: grid-px();
  }

  &__title {
    display: flex;
    align-items: center;
    flex-grow: 1;
    padding: 0px grid-px(2.5);
    overflow: hidden;
  }

  &__header-btns {
    display: flex;
    border-left: 1px solid $color-gray-3;
    padding: 0 grid-px(2);
    align-items: center;
    justify-content: space-between;

    button {
      background: none;
      border: none;
      padding: 0px;
      cursor: pointer;
      margin-right: grid-px(2.25);

      svg {
        @include filter-gray-2;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  main {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.03) 0, transparent 16px, transparent 100%);
  }
}
