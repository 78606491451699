@import '../../styles/index.scss';

.schedule-options {
  display: flex;
  align-items: center;
  width: max-content;
  max-height: grid-px(5);
  padding: grid-px(0.5);
  background-color: $color-gray-6;
  border-radius: 8px;

  &__option {
    display: inline-block;
    text-align: center;
    min-width: grid-px(16.75);
    padding: grid-px();
    max-height: grid-px(4);
    color: $color-gray-3;
    font-size: $font-size-standard;
    font-family: $font-default;
    font-weight: 500;
    border: none;
    background: none;
    border-radius: $border-radius-standard;

    &--active {
      background-color: $color-white;
      color: $color-black;
      border: $border-light;
    }
  }
}
