@import '../../styles/index';

#scrollable-options {
  position: relative;
  max-height: grid-px(50);
  padding-right: 0;
  box-sizing: border-box;
  overflow-y: auto;

  .loading-content {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
