@import "../../../../././../styles/index.scss";

.sequence-cell {
    cursor: pointer;

    .tooltip__container {
        display: block;
    }

    &__title {
        @include text-overflow-ellipsis;
        color: $color-blue-base;
        cursor: pointer;
        margin: 0 grid-px(1);
    }
}
