@import '../../styles/index.scss';

.vast-preview {
  & > div {
    margin: 0;
  }

  input:disabled {
    background-color: $white-background;
  }

  &__creative-card {
    background-color: $color-gray-6;
    border: 1px solid $color-gray-3;
  }

  &__save-prompt {
    color: $color-gray-2;
    margin: grid-px(2.5) grid-px(2);
    font-size: $font-size-standard;
    line-height: grid-px(2.5);
  }
}
