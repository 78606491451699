.creative-review-badge {
  .alert {
    position: relative;
  }

  .alert__content {
    padding: 6px;
    margin: 0;
    width: auto;
  }

  .alert__icon {
    flex-basis: 36px;
  }

  .alert__body-text {
    margin: 0;
  }

  .alert__type,
  .alert__close-btn {
    display: none;
  }
}
