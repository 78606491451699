@import '../../styles/index';

.delete-btn {
  @include button-reset;

  svg {
    width: grid-px(2.625);
    vertical-align: middle;
    @include filter-gray-2;
  }
}
