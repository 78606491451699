@import '../../../styles/index.scss';

.export-dropdown {
  height: grid-px(2.5);
  position: relative;

  &__button {
    display: flex;
    align-items: center;
    color: $color-gray-2;
    font-size: $font-size-standard;
    line-height: 1.9;
    cursor: pointer;
    letter-spacing: normal;
    font-weight: 600;

    &:hover {
      color: $color-black;

      path {
        fill: $color-black;
      }
    }
  }

  &__loader {
    display: flex;
    align-items: center;

    width: grid-px(15);
  }

  &__tooltip {
    position: absolute;
    top: 30px;
    right: -15px;
    width: grid-px(24);

    padding: grid-px(0);
    border-radius: grid-px(0.5);

    box-shadow: $tooltip-shadow;
    background-color: $color-white;
    z-index: 1000;

    li {
      padding: grid-px(1) grid-px(2);
      border-radius: grid-px(0.5);

      list-style-type: none;
      cursor: pointer;

      &:hover {
        background-color: $color-gray-6;
      }
    }
  }

  &__download-icon {
    width: grid-px(3);
    height: grid-px(3);
    margin-right: grid-px(1.125);

    path {
      fill: $color-gray-3;
    }
  }

  &__csv-link {
    display: none;
  }
}
