@import "../../../../styles/index.scss";

.gateway-go-builder {
    &__long-text-field {
        .text-field-container {
            width: grid-px(60) !important;
        }
    }

    &__multiline-text-field {
         .TrekParagraphInput-root {
            max-width: 100%;
            width: initial !important;
        }
        margin-right: 0;
    }
}
