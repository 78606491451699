@import '../../../../../styles/index.scss';

.duplicate-ad-form {
  margin-bottom: grid-px(3);

  &__duplicate-info {
    font-size: 16px;
    line-height: 20px;
  }

  &__form-content {
      display: flex;
      flex-direction: row;

      margin-top: grid-px(3);
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .card {
    width: grid-px(89);
    margin-top: grid-px(1.5);

    .text-field-container {
      width: 40%;
      margin-left: grid-px(3);
    }
  }
}
