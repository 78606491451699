@import '../../../../../../../styles/index.scss';

$multiple-edit-drawer-body-content-border-width: 1px 1px 1px 0px;
$multiple-edit-drawer-body-content-padding: 25px;
$multiple-edit-drawer-body-content-height: calc(100vh - 202px);

.multiple-edit-drawer-content-second-step {
  &__edit-message {
    line-height: grid-px(2);
    font-size: grid-px(1.75);
    margin-left: grid-px(18);
    margin-bottom: grid-px(1.5);
    font-weight: normal;
  }

  .multiple-edit-drawer-content-header {
    padding-bottom: grid-px(1.5);
  }

  &__buttons {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > button {
      &:first-of-type {
        margin-right: grid-px();
        background: $color-gray-5;
        color: $color-gray-1;
        &:hover {
          background: $color-gray-4;
        }
      }
      &:last-of-type {
        background: $color-blue-base;
        color: $color-white;
        &:hover {
          background: $color-blue-dark;
        }
      }
      &.TrekButton-disabled {
        background: $color-gray-6;
        color: $color-gray-3;
      }
    }
  }

  &__body {
    display: grid;
    grid-template-columns: grid-px(18) 1fr;
    grid-template-rows: 1fr;

    &--line-items {
      .side-nav__nav-link {
        line-height: grid-px(2.25);
      }
    }

    &__content {
      width: 100%;
      background-color: $color-gray-6;
      border: $light-border;
      border-width: $multiple-edit-drawer-body-content-border-width;
      padding: $multiple-edit-drawer-body-content-padding;
      overflow-y: scroll;
      height: $multiple-edit-drawer-body-content-height;

      > div {
        margin-top: grid-px(4);

        &:first-child {
          margin-top: 0px;
        }
      }
    }
  }
}
