@import '../../styles/index.scss';

.stepper {

  &__buttons {
    display: flex;

    &__right-block {
      display: flex;
      margin-left: auto;
    }
  }

  button.stepper__cancel-button {
    border: none ;
    color: $color-blue-1;
  }

  &__next-submit-btn {
    justify-self: flex-end;
    align-self: flex-end;
    margin-left: auto;
  }
}
