@import '../../../styles/index.scss';

$filter-color-faux-black: invert(2%) sepia(14%) saturate(383%) hue-rotate(204deg) brightness(92%) contrast(101%);

.editable-cell {
  height: $cell-height;
  line-height: $cell-height;
  position: relative; // allow icons to be positioned inside cell wrapper
  padding: 1px;

  &--base {
    &:hover,
    &:focus,
    &:focus-within {
      .editable-cell__icon--base {
        display: block;
        // !important to overwrite row hover styles
        filter: $filter-color-faux-black !important;
        z-index: 1;
      }
    }

    &:hover {
      // Render inset box-shadow instead of border to prevent table cells shifting with changes to border width
      box-shadow: 0 0 0 1px $color-gray-2 inset;
    }

    &:focus,
    &:focus-within {
      box-shadow: 0 0 0 1px $color-blue-base inset;
      outline: none;
    }
  }

  &--disabled {
    color: $color-gray-2;
    cursor: not-allowed;
  }

  &--saving {
    background: $color-white;
    cursor: progress;
  }

  &--save-successful {
    background: $color-green-6;
    box-shadow: 0 0 0 1px $color-green-1 inset;

    &:hover {
      background: $color-green-5;
      box-shadow: 0 0 0 2px $color-green-1 inset;

      .editable-cell__icon {
        display: none;
      }
    }
  }

  &--save-failed {
    background: $color-red-6;
    box-shadow: 0 0 0 2px $color-red-base inset;

    &:hover {
      background: $color-red-5;
      box-shadow: 0 0 0 2px $color-red-base inset;

      .editable-cell__icon {
        display: none;
      }
    }
  }

  &__icon {
    position: absolute;
    top: grid-px(1.5);
    right: grid-px(1.5);

    &--saving {
      width: grid-px(3);
    }

    &--save-successful {
      path {
        fill: $color-green-base;
      }
    }

    &--save-failed {
      width: 14px;
      @include filter-red-base;
    }

    &--base {
      display: none;
      // these values are precise to help with vertical centering
      margin-top: 1px;
      filter: $filter-color-faux-black;
    }
  }
}
