@import '../../../styles/index.scss';

.multi-option {
  &__clear-text {
    color: $color-blue-base;

    &:hover {
      color: $color-blue-dark;
      text-decoration: underline;
    }

    &--disabled {
      color: $color-gray-3;

      &:hover {
        color: $color-gray-3;
        text-decoration: none;
        cursor: not-allowed;
      }
    }
  }
}
