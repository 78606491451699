@import '../../../styles/index.scss';

.editable-percent-input {
  font-size: $font-size-standard;
  // must be relative so that modal is positioned correctly
  position: relative;
  height: $cell-height;
  width: 100%;
  background: transparent;

  &--with-border {
    height: 75px;

    .editable-cell {
      border: 1px solid $border-color-light;
    }
  }

  &__labelText {
    font-weight: $font-weight-bold;
    font-size: $font-size-small;
    text-transform: uppercase;
    margin-bottom: 8px;
  }

  &__display {
    font-size: $font-size-standard;
    height: $cell-height;
    line-height: $cell-height;
    @include text-overflow-ellipsis;
  }
  &__rendered-value {
    line-height: grid-px(5);
    vertical-align: middle;
    padding-left: grid-px(2);
  }

  .TrekTextField-root.TrekTextField-root {
    font-size: $font-size-standard;
    float: none;
    margin: 0;
    padding-top: grid-px(0.5);

    > input {
      padding-left: grid-px(2);
    }
  }
}

.editable-cell:hover {
  .editable-percent-input {
    cursor: pointer;
  }

  > svg {
    display: block;
  }

  &--disabled {
    cursor: not-allowed;
  }
}
