@import '../../../../../../styles/index.scss';

.assign-to-line-item {
  h3 {
    font-size: grid-px(1.5);
    font-weight: bold;
    line-height: grid-px(1.5);
    margin: 0;
    text-transform: uppercase;
  }

  &__title {
    font-size: grid-px(2);
    font-weight: normal;
    line-height: grid-px(3);
  }

  &__line-items-wrapper {
    height: grid-px(62.5);
    overflow: auto;
    margin-top: grid-px(3);
  }
}
