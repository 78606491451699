@import '../../../../../../../styles/index.scss';

.multi-edit-rating {
  padding-bottom: grid-px(18.25);

  &__header {
    color: $text-dark;
    font-size: $font-size-small;
    margin-top: grid-px(1.25);
    font-weight: 400;
    line-height: grid-px(3);
    margin-bottom: grid-px(2);
  }

  &__dropdown-wrapper {
    display: flex;
    justify-content: flex-start;
  }

  .dropdown {
    flex: 0 1 70%;
  }
}
