@import '../../../styles/index.scss';
@import '../Form.scss';

.switch {
  margin: $form-element-margin;

  &__group {
    display: flex;
    align-items: center;
  }

  &__info {
    margin-left: grid-px(2);
    font-size: 12px;
    color: $color-gray-2;
  }
}
