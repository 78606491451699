@import '../../../styles/index.scss';

.override-review-button {
  @include common-table-header-link;

  svg {
    height: grid-px(2);
  }

  &.TrekButton-disabled {
    svg {
      filter: none;
      path {
        fill: $color-gray-4;
      }
    }
  }
}
