@import '../Form.scss';

// Todo: update when Trek components become available for dropdown and switch in AX-1492
.select {
  margin: $form-element-margin;

  select {
    display: block;
    width: 100%;

    &:disabled {
      font-style: italic;
      appearance: none; // Hide dropdown arrow
    }
  }
}
