@import '../../../styles/index.scss';

.creative-breadcrumb {
  height: grid-px(3);
  display: flex;
  align-items: center;

  &__selectOption-button {
    @include keyboard-focus-only;
    background: none;
    border: none;
    padding: 0;

    &:disabled span {
      color: $text-black;
      text-decoration: none;
      cursor: text;
    }
  }
}
