@import '../../../../../styles/index';

.change-log-cell {
  font-size: $font-size-small;
  word-break: break-all;

  &__plus-icon {
    margin-top: -2px;
    margin-right: 3px;
  }

  &--removal {
    text-decoration: line-through;
    text-decoration-color: $color-red-input-error;
  }

  &__comma-list-container {
    display: contents;
  }

  &__list-container {
    display: inline-grid;
    padding: 0 0 0 grid-px(2);
    margin: 0;
    li:not(:last-of-type) {
      padding-bottom: grid-px(0.5);
    }
  }
}
