@import '../../../styles/index.scss';

.trafficking-page-drawer {
  overflow-y: scroll;

  &--expanded {
    main {
      grid-template-columns: minmax(290px, 1fr) 4fr;
    }
  }
}
