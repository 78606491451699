@import '../../styles/index.scss';

.timezone {
  display: inline-block;
  margin-bottom: grid-px(2);
  color: $text-dark;
  font-weight: 500;
  font-size: $font-size-standard;
  text-transform: uppercase;
}
