// no outline for items that are focused by non-keyboard events (aka not focus-visible)
@mixin keyboard-focus-only {
  &:focus:not(:focus-visible) {
    box-shadow: none;
    outline: none;
  }
}

@mixin segmented-control-nav {
  background: $color-gray-5;
  border-radius: grid-px();
  width: fit-content;
  padding: 4px;
  display: flex;
  justify-content: center;

  .divider.TrekTabNavigationTab {
    &:after {
      content: '';
      width: 1px;
      height: 16px;
      background-color: $color-gray-2;
      display: block;
      margin: grid-px() 0;
      left: calc(100% + 1px);
      opacity: 0.3;
      line-height: 22px;
      border: 0;
    }
  }

  .TrekTabNavigationTab {
    color: $color-faux-black;
    height: unset;
    line-height: 22px;
    flex: 1;
    min-width: 134px;
    text-transform: none;
    letter-spacing: unset;
    display: inline-block;
    text-align: center;
    padding: 5px 0;
    margin: 0 1px;

    &::after {
      display: none;
    }

    &:hover,
    &:focus {
      background-color: $color-white-3;
      border-radius: grid-px(0.5);
      outline: none;
    }

    &-selected {
      background: $white-background;
      border-radius: grid-px(0.5);
      box-shadow: 0 3px 1px 0 rgba(0, 0, 0, 0.04), 0 3px 8px 0 rgba(0, 0, 0, 0.12);

      &:after {
        border-bottom: 1px solid $white-background;
      }
    }
  }
}

@mixin auto-resizing-textarea {
  border-radius: 4px;
  border: 1px solid $color-gray-3;
  box-sizing: border-box;
  color: $color-faux-black;
  display: block;
  font-family: $font-default;
  font-size: $font-size-standard;
  line-height: 1.5;
  padding: 9px 12px;

  &:hover {
    border: 2px solid $color-gray-2;
    padding: 8px 11px;
  }

  &:focus {
    border: 2px solid $color-faux-black;
    padding: 8px 11px;
  }
  @include keyboard-focus-only;
}

// removes default button styling but maintains accessibility
@mixin button-reset {
  font: inherit;
  border: none;
  background: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;

  &:hover {
    background: none;
  }
  @include keyboard-focus-only;
}

@mixin sticky-cell {
  position: sticky;
  top: 0;
  left: 0;
  background-color: $color-white;
  z-index: $trafficking-table-header-z-index + 1;
  overflow: hidden;

  &--name {
    border-right: 1px solid $color-gray-5;
    left: 72px;
  }
}

@mixin toggle-transition {
  &__toggle {
    &-enter {
      opacity: 0;
      height: 0;
      transform: scaleY(0);
      transform-origin: top;
    }

    &-enter-active {
      transition: all 150ms ease-out;
      opacity: 1;
      height: 100%;
      transform: scaleY(1);
    }

    &-exit {
      opacity: 1;
      height: 100%;
      transform-origin: top;
      transform: scaleY(1);
    }

    &-exit-active,
    &-unmounted {
      opacity: 0;
      height: 0;
      transform: scaleY(0);
      transition: all 150ms ease-out;
    }
  }
}

@mixin sticky-cell-position {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

@mixin text-overflow-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin common-table-header-link {
  &__tooltip {
    max-width: grid-px(25);
  }

  svg {
    margin-right: grid-px();
    @include filter-gray-3;
  }

  &:hover {
    .TrekButton-label {
      color: $color-faux-black;
    }

    svg {
      @include filter-faux-black;
    }
  }

  &.TrekButton-disabled {
    .TrekButton-label {
      color: $color-gray-3;
    }

    svg {
      @include filter-gray-disabled;
      user-select: none;
    }
  }
}

@mixin form-grid-display {
  display: grid;
  margin: $form-element-margin;
  grid-template-columns: grid-px(35) grid-px(35);
  column-gap: $form-element-margin-column;
  row-gap: $form-element-margin-row;
  justify-items: stretch;
}

@mixin form-grid-child {
  > div {
    margin: 0; // override the default margins and use the grid exclusively
  }
}

@mixin table-outlined-button {
  border: none;
  margin: 0;

  svg {
    width: 22px;
    @include filter-gray-2;
  }
}
