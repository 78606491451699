@import '../../styles/index.scss';

.time-matrix {
  margin: 30px 0px 24px 35px;
  &__content {
    display: flex;
    flex-flow: row;
  }
  &__content-inner {
    border: 1px solid $color-gray-2;
    position: relative;
    .time-matrix__hours-names, .time-matrix__days-names {
      position: absolute;
    }

  }
  &__hours-names {
    width: 100%;
    top: -20px;
    display: flex;
    > span {
      flex-basis: 47px;
    }
  }
  &__days-names {
    top: 0;
    left: -20px;
    display: flex;
    flex-direction: column;
    > span {
      flex-basis: 53px;
      display: flex;
      align-items: center;
    }
  }
  &__column {
    display: flex;
    &:not(:last-child) {
      border-bottom: 1px solid $color-gray-2;
    }
  }
  &__cell {
    height: 52px;
    background-color: $color-gray-5;
    width: 15px;
    appearance: none;
    margin: 0;
    &--active {
      background-color: $color-brand-primary;
    }
  }
  &__column, &__all-row {
    .time-matrix__cell {
      &:not(:last-child) {
        margin-right: 1px;
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      &:nth-child(3n + 3) {
        border-right: 1px solid $color-gray-2;
      }
      &:last-child {
        border-right: none;
      }
    }
  }
  &__all-column {
    position: relative;
    flex-basis: 47px;
    display: flex;
    flex-flow: column;
    border: 1px solid $color-gray-2;
    margin-left: grid-px(2);
    > .time-matrix__cell {
      width: 47px;
      height: 100%;
      flex-grow: 1;
      &:not(:last-child) {
        border-bottom: 1px solid $color-gray-2;
      }
    }
  }
  &__all-row {
    border: 1px solid $color-gray-2;
    margin-top: grid-px(2);
    display: inline-flex;
    position: relative;
    &-content {
      height: 52px; 
    }
  }
  &__all-row-title {
    position: absolute;
    left: -38px;
    top: 15px;
  }
  &__all-column-title {
    position: absolute;
    top: -20px;
    left: 8px;
  }
}