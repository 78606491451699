@import '../../../styles/index.scss';

.dropdown-button {
  &__empty-text {
    color: $color-gray-2;
  }

  .TrekDropDownItem-label {
    font-size: $font-size-small;
  }

  .TrekDropDown-root.TrekDropDown-disabled {
    cursor: not-allowed;
  }

  &__remove-btn {
    position: absolute;
    top: 2px;
    right: 0;
  }

  button.trek-dropdown {
    pointer-events: none;

    &.TrekDropDown-formControl:focus {
      // Firefox
      outline: 5px auto Highlight;
      //Chrome and Safari
      outline: 5px auto -webkit-focus-ring-color;
    }

    &.TrekDropDown-formControlActive {
      border-width: 1px;
    }
  }
}
