@import '../../../../../../styles/index.scss';

.filter-button {
  display: flex;
  align-items: center;

  padding: grid-px(1.5) grid-px(3.25);
  background-color: $color-black;

  border-radius: grid-px(0.5);
  cursor: pointer;

  span {
    margin: 0 grid-px(1);
    color: $color-white;

    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
  }

  &__funnel-icon {
    > path {
      fill: $color-white;
    }
  }

  &__chevron-icon {
    > path {
      fill: $color-white;
    }
  }
}
