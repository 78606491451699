@import '../../../../styles/index.scss';

.button-group{
  display: flex;

  a.TrekButton-outlined.TrekButton-regular:focus {
    border-color: $border-color-light;
    outline: none;
  }
}
