@import "../../styles/index.scss";

.text-input-with-actions {
    display: flex;
    line-height: grid-px(3);
    position: relative;

    &--error {
        font-weight: normal;
        margin: grid-px() 0;
    }

    &__edit-icon {
        margin-left: grid-px(1.5);
        cursor: pointer;
        height: grid-px(3);

        svg path {
            fill: $color-gray-2;
        }

        &:hover {
            svg path {
                fill: $color-faux-black;
            }
        }
    }

    &__buttons {
        position: absolute;
        right: -90px;
        top: grid-px(0.125);
        z-index: 1;

        button {
            border-radius: $border-radius-standard;
            border: 1px solid $border-color-light;
            box-shadow: $raised-shadow-standard;
            padding: 0;
            box-sizing: border-box;
            height: grid-px(4.5);
            margin-left: grid-px(0.5);
            width: grid-px(4.5);
            cursor: pointer;

            &:not(:focus) {
                outline: none;
            }

            &:active {
                // when clicked, button should look like it's pressed
                transform: translateY($shadow-height-standard);
                box-shadow: none;
            }

            svg {
                vertical-align: middle;
            }
        }
    }

    &__cancel-btn {
        background: $color-white;
    }

    &__submit-btn {
        background: $color-black-1;

        svg {
            @include filter-white;
        }
    }

    &__input-block {
        display: block;
    }

    &__error {
        margin-top: grid-px(0.5);
        color: $color-red-input-error;
    }

    &__value-label {
        max-width: grid-px(25);
        @include text-overflow-ellipsis;
    }
}
