@import '../../../styles/index.scss';
@import '../../../common/Form/Form.scss';

.sequence-form {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__content {
    padding: grid-px(1.5) 0 0;
    overflow-y: auto;
    height: calc(#{$ads-content-height} - #{$ads-header-height});

    > div {
      margin: grid-px(4);
    }

    > div:first-child {
      margin-top: 0;
    }
  }

  // Styles for all form sections
  .title {
    font-size: grid-px(2.25);
    line-height: grid-px(3);
  }

  .card {
    margin-top: grid-px(1.5);
    width: grid-px(180);
    &__content {
      margin: 0 auto;
    }
  }

  &__submit-title {
    display: flex;
    align-items: center;

    span {
      margin-right: grid-px(0.5);
    }
  }
}
