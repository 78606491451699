.sort-arrow {
  margin-left: 6px;

  svg {
    height: 14px;
    width: 14px;
    bottom: -3px;
    position: relative;
  }
}
