@import '../../../../../styles/index.scss';

.pacing-status-cell {
  flex-direction: row;
  @include text-overflow-ellipsis;

  .tooltip__container {
    display: initial;
    max-width: 180px;
    word-wrap: wrap;
  }

  &__title {
    margin: grid-px();
    display: inline;
    vertical-align: super;
  }

  &--overpacing {
    color: $color-blue-dark;

    svg {
      path {
        fill: $color-blue-dark;
      }
    }
  }

  &--underpacing {
    color: $color-red-input-error;

    svg {
      path {
        fill: $color-red-input-error;
      }
    }
  }

  &--onpace {
    color: $color-brand-primary;

    svg {
      path {
        fill: $color-brand-primary;
      }
    }
  }
  &--delivered {
    color: $color-brand-primary;

    svg {
      path {
        fill: $color-brand-primary;
      }
    }
  }
  &--underdelivered {
    color: $color-red-input-error;

    svg {
      path {
        fill: $color-red-input-error;
      }
    }
  }
}
