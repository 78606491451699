@import '../../styles/index.scss';

.change-log {
  display: block;

  &-loader {
    margin-top: 10px;
  }

  button {
    color: $color-blue-base;
    cursor: pointer;
    border: none;
    background: none;
  }
}

.loading-content {
  color: $color-gray-3;

  .loading-text {
    vertical-align: super;
    margin-left: grid-px(0.5);
  }

  img {
    height: grid-px(3);
  }
}
