@import "../../../styles/index";

.dnd-list {
  margin-bottom: grid-px(0.75);

  &__error {
    margin-left: grid-px();
    color: $color-red-base ;
  }
}
