@import '../../AdTagsTable/AdTagsTable.scss';

textarea.new-ad-tag-row__url-textarea--expanded {
  width: $url-width + $event-width + 2 * $table-cell-padding;
}

.new-ad-tag-row {
  td {
    padding-top: 20px;
  }

  &__add-btn.TrekButton-root {
    padding-left: 20px;
    padding-right: 20px;
    margin: 0;
  }
}
