@import '../../../styles/index.scss';

.drawer-targeting-table {
  .targeting-dropdowns {
    max-width: 473px;
    margin-bottom: grid-px(4);

    button {
      padding: grid-px(1.5) grid-px(2.25);
      min-width: 136px;
    }
  }

  &__publisher {
    font-weight: 600;
    margin-bottom: 15px;
  }

  &__country {
    font-weight: 600;
    margin-bottom: 15px;
  }
}