@import '../../../../../styles/index.scss';

$title-badge-font-size: 10px;
$title-text-font-size: 16px;

.entity-details-drawer-title {
    display: flex;
    align-items: center;
    font-size: $title-text-font-size;
    font-weight: normal;
    line-height: 1;
    margin: 0px;

    &__text {
        margin-left: grid-px(.5);
    }
}