@import "../../styles/index.scss";

.toggle-section {
  &__title-wrapper {
    background-color: $color-gray-5;
    line-height: $font-size-standard * 1.5;
    border-top: $light-border;
    border-bottom: 1px solid $border-color-header;
    border-right: none;
    border-left: none;
    cursor: pointer;
    padding: grid-px(1.625);
    font-size: $font-size-standard;
    display: flex;
    align-items: center;
    color: $color-gray-1;
    width: auto;

    &:hover {
      background-color: $color-gray-4;
    }

    svg.chevron {
      will-change: transform;
      transition: transform 150ms linear;
      height: $font-size-standard + 2;
    }

    &--active {
      svg.chevron {
        transform: rotate(90deg);
        transform-origin: ($font-size-standard) ($font-size-standard - 4);
      }
    }
  }

  &__title {
    padding-left: grid-px();
  }

  &__title-content-wrapper {
    display: flex;
    align-items: center;
    width: auto;
  }

  &__action {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    gap: grid-px();
  }

  @include toggle-transition;
}
