// !important is used here to rewrite table styles from imported commonTable
@import '../../../../../../styles/index.scss';

$multiple-edit-drawer-content-table-thead-border-radius: 5px;
$multiple-edit-drawer-content-table-checkbox-color: #0B0C0F;
$multiple-edit-drawer-content-table-checkbox-size: 17px;
$multiple-edit-drawer-content-table-thead-checkbox-height: 25px;
$multiple-edit-drawer-content-table-thead-first-th-width: 45px;

.multiple-edit-drawer-content-table {
  overflow-y: auto;
  max-height: calc(100vh - 365px);

  .controlled-checkbox {
    > input {
      display: inline-block !important;
      accent-color: $multiple-edit-drawer-content-table-checkbox-color;
      width: $multiple-edit-drawer-content-table-checkbox-size;
      height: $multiple-edit-drawer-content-table-checkbox-size;
    }

    &__label-content {
      display: none;
    }
  }

  tr[role='row'] {
    &:hover,
    &:focus-within {
      background: transparent !important;
    }
    &.checked.checked {
      background-color: transparent !important;
    }
  }

  td[role='cell'] {
    &.selected:focus-within {
      box-shadow: 0 0 0 0px transparent !important;
    }
  }

  th {
    font-size: $font-size-standard !important;
  }

  .common-table__table-wrapper {
    overflow: hidden !important;
  }

  .common-table__table {
    border-width: 0px;
    table-layout: fixed;
  }

  thead {
    tr {
      background-color: $color-gray-4 !important;
      border-bottom: 1px solid $color-grey-light !important;
    }

    th {
      background-color: $color-gray-4 !important;
      border-bottom: 1px solid $color-grey-light !important;

      &:first-of-type {
        border-top-left-radius: $multiple-edit-drawer-content-table-thead-border-radius !important;
        width: $multiple-edit-drawer-content-table-thead-first-th-width;
      }

      &:last-of-type {
        border-top-right-radius: $multiple-edit-drawer-content-table-thead-border-radius !important;
      }
    }

    .controlled-checkbox {
        height: $multiple-edit-drawer-content-table-thead-checkbox-height;

      > input {
        width: $multiple-edit-drawer-content-table-checkbox-size;
        height: $multiple-edit-drawer-content-table-checkbox-size;
      }
    }
  }

  table {
    border-collapse: collapse;
  }
}
