@import '../../../../styles/index.scss';

.create-review-modal {
  .ant-modal-close-x {
    margin: 10px 3px 0 0;

    svg {
      width: 26px;
      height: 25px;
    }
  }

  .ant-modal-footer {
    padding: 0 16px 14px 16px;
    border-top: none;

    .ant-btn {
      padding: 0 0 0 7px;
      background: none;
      outline: none;
      border: none;
    }
  }

  &__notification {
    width: grid-px(70);
  }

  &__submit-button {
    text-decoration: none;
    height: 36px;
    box-sizing: border-box;

    span {
      display: flex;
      align-items: center;
      font-weight: 500;
    }

    svg {
      width: 14px;
      filter: brightness(0) invert(1);
      margin-left: grid-px();
    }
  }

  &__cancel-button {
    font-family: inherit;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 14px;
    text-transform: uppercase;
    color: $color-blue-base;
    font-weight: 600;
    cursor: pointer;
    margin-right: 19px;
  }
}
