@import '../../../../styles/index.scss';

.daypart {  
  &__daypart-headers {
    display: grid;
    grid-template-columns: 146px 146px repeat(7, 1fr) 44px;
    align-items: center;
    justify-items: center;
    border-bottom: 1px solid $color-gray-4;
    padding: grid-px();
    font-weight: 600;
    font-size: $font-size-standard;

    &--time-header {
      justify-self: start;
    }
  }

  &__daypart-row {
    display: grid;
    grid-template-columns: 146px 146px 1fr 44px;
    align-items: center;
    justify-items: center;
    border-bottom: 1px solid $color-gray-5;
    padding: grid-px(0.5) grid-px();

    & fieldset {
      display: grid;
      border: none;
      width: 100%;
      justify-items: center;
      align-items: center;
      grid-template-columns: repeat(7, 1fr);
      padding: 0;
      margin: 0;

      & input {
        height: 16px;
        width: 16px;
        border-radius: 2px;
      }
    }
  }

  & input.daypart__time-input {
    width: 96px;

    // accounts for firefox and the extra digits
    @-moz-document url-prefix() {
      width: 100px;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      appearance: none;
      -webkit-appearance: none;
    }
  }

  &__time-input {
    justify-self: start;
    padding: 11px 10px 11px 12px;
    font-family: $font-default;
    font-size: $font-size-standard;
    margin-right: grid-px();
    border-radius: grid-px(0.5);
    border: 1px solid $border-color-light;
  }

  &__delete-btn {
    @include button-reset;
    margin-left: 18.5px;

    svg {
      width: 21px;
      vertical-align: middle;
      @include filter-gray-2;
    }
  }

  &__add-btn.TrekButton-root {
    width: 100%;
    margin: grid-px() 0 0 0;
  }

  &__daypart-validation {
    color: $color-red-input-error;
    font-size: $font-size-small;
    line-height: 1.3;
    margin: grid-px(1) 0 grid-px(2) grid-px(1);
  }
}
