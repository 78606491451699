@import '../../../styles/index.scss';

.trafficking-page-drawer {
  .schedule-calendar {
    display: flex;
    flex-direction: column;
    &__date-title {
      display: flex;
      margin-bottom: grid-px();
      align-items: center;
      svg {
        margin-right: grid-px();
  
        path {
          fill: $color-gray-2;
        }
      }
      h5 {
        margin: 0;
        color: $color-gray-2;
        font-size: 18px;
      }
    }
    &__segmented-control {
      @include segmented-control-nav;
      &--disabled {
        .TrekTabNavigationTab {
          color: $color-gray-3;
          background-color: initial;
          &:focus,&:hover {
            background-color: initial;
          }
          &-selected {
            color: $color-black;
            background-color: $color-white;
            &:hover {
              background-color: $color-white;
            }
          }
        }
      }
      flex-direction: row;
      .TrekTabNavigationTab-selected {
        clip-path: none;
      }
    }
  }
}