@import "../../../../../styles/index.scss";

.creative-section-wrapper {
    margin: grid-px(5) 0;

    &__title-wrapper {
        line-height: grid-px(3);
        margin-left: grid-px(3);
        font-size: grid-px(2.25);
        color: $color-grey-light;
        font-weight: 600;
        margin-bottom: 0;
    }

    &__icon {
        svg {
            margin-right: grid-px(0.5);

            path {
                fill: $color-grey-light;
            }
        }
    }

    &__title {
        vertical-align: top;
    }

    &__children-wrapper {
        .text-field-container {
            margin: grid-px(1) grid-px(3) grid-px(2);
        }

        .TrekParagraphInput-root {
            margin: grid-px(1) grid-px(3) grid-px(2);

            textarea {
                max-height: grid-px(10);
            }
        }
    }
}
