@import '../../../../styles/index.scss';

.entity-details-drawer {
  main {
    display: grid;
    grid-template-columns: grid-px(9) 1fr;
    grid-template-rows: 1fr;
    height: 100%;
    width: 100%;

    // tab panel content
    > div {
      background: linear-gradient(to right, rgba(0, 0, 0, 0.03) 0, transparent 16px, transparent 100%);
    }
  }
}
