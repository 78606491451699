@import '../../../../../../../../../../styles/index.scss';

$multiple-edit-status-table_header_margin_top: 18px;
$multiple-edit-status-table_header_margin_bottom: 16px;
$multiple-edit-status-table_header_font_weight: 400;
$multiple-edit-status-table_header_line_height: 18px;
$multiple-edit-status-table_button_margin_top: 18px;

.multiple-edit-status-table {
  &__header {
    color: $text-dark;
    font-size: $font-size-small;
    margin-top: $multiple-edit-status-table_header_margin_top;
    font-weight: $multiple-edit-status-table_header_font_weight;
    line-height: $multiple-edit-status-table_header_line_height;
    margin-bottom: $multiple-edit-status-table_header_margin_bottom;
  }

  &__button {
    display: flex;
    justify-content: flex-end;
    margin-top: grid-px(4);

    > button {
      letter-spacing: 1px;
      text-transform: uppercase;
      margin: 0px;
    }

    &--last {
        margin-bottom: $multiple-edit-status-table_button_margin_top;
    }
  }

  &__cell {
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

  thead {
    th {
      &:last-of-type {
        width: 30%;
      }
    }
  }

  &--expended {
    thead {
      th {
        &:last-of-type {
          width: 14%;
        }
      }
    }    
  }

  .tooltip__container {
    justify-content: flex-start; 
  }
}
