@import '../../../styles/index.scss';

.dates-info-content {
  &__fieldset {
    display: flex;
    justify-content: space-between;

    .daterange {
      margin-bottom: grid-px();
    }

    &--column {
      flex-direction: column;
      align-items: flex-start;

      .timezone-wrapper {
        margin: 0 0 grid-px(4);
      }

      .dateranges {
        margin-bottom: grid-px(2);
      }
    }
  }

  &__date-range-switch-disable-end-date{
    font-size: grid-px(1.5);
    display: flex;
    align-items: center;
    margin: grid-px() 0;


    >span{
      margin-right: grid-px(1.5);
    }
  }

  &__date-range-list-title {
    margin-top: grid-px();
    margin-bottom: 0;
    display: flex;
    color: $color-black;
    text-transform: uppercase;
    font-size: $font-size-small;
    font-weight: 600;

    &-end {
      //brought from datetime_input in .daterange
      width: grid-px(18.25);
    }

    &-start {
      margin-right: grid-px(2.25);
      //brought from datetime_input in .daterange
      width: grid-px(25);
    }
  }

  &__date-range-list-items {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    align-items: flex-start;
    //used to hide the shifting effect of scrollbar
    padding-left: grid-px(0.075);
    max-height: grid-px(67.5);
  }

  &__date-range-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__date-range {
    position: relative;
  }
    
  &__remove-date-range {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 100%;
  }

  &__validation {
    font-size: $font-size-small;
    line-height: 1.3;
    margin-bottom: grid-px(2);
    margin-top: grid-px(0.5);
    color: $color-red-input-error;
  }

  .timezone-wrapper {
    margin: grid-px(4.375) 0 0 grid-px(2);

    // Match sibling field height
    .dropdown-button button.trek-dropdown {
      height: 35px;
    }

    .dropdown--select-timezone {
      width: 100%;
    }
  }

  &__add.TrekButton-root {
    margin: grid-px(0.75) 0;
    width: 100%;
  }

}
