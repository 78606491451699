@import '../../styles/index.scss';

.rotations-modal {
  .TrekTabNavigationTab {
    line-height: 22px;
    width: 148px;
  }

  .ant-modal-footer {
    box-sizing: border-box;
    box-shadow: 0 4px 14px -1px rgba(0, 0, 0, 0.2);
    height: 84px;
    display: flex;
    align-items: center;

    .ant-btn {
      background: none;
      border: 1px solid transparent;

      &:active,
      &:focus {
        outline: 1px solid transparent;
      }
    }
  }

  &__cancel-button {
    font-family: inherit;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 14px;
    text-transform: uppercase;
    color: $color-blue-base;
    font-weight: 600;
    cursor: pointer;
    margin-right: grid-px(2);
  }

  &__submit-button {
    height: grid-px(4.5);
    box-sizing: border-box;
    align-items: center;
    background: $color-faux-black;
    border-radius: grid-px(0.5);
    border: 1px solid transparent;
    color: $color-white;
    cursor: pointer;
    display: inline-flex;
    font-family: Graphik, sans-serif;
    font-size: $font-size-small;
    font-weight: 600;
    justify-content: center;
    line-height: $font-size-small;
    margin: grid-px(0.75);
    padding: 7px grid-px(3);
    pointer-events: auto;
    text-decoration: none;
    text-transform: uppercase;
    &:hover,
    &:focus {
      background-color: $color-black-2;
      outline: none;
    }
  }

  &__alert {
    align-items: center;
    background-color: $color-yellow-6;
    border: 1px solid $color-yellow-base;
    display: flex;
    font-size: $font-size-small;
    justify-content: center;
    margin-right: 10px;
    padding: 9px;
    width: 170px;

    & svg {
      margin-right: 18px;
      path {
        fill: $color-yellow-base;
      }
    }
  }

  &__modal-footer {
    margin-left: auto;
  }

  .ant-modal-close-icon svg {
    width: 20px;
    height: 20px;
    padding-top: grid-px(3);
    padding-right: grid-px(1);
  }
}

.rotations {
  display: block;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__header-text {
    font-size: $font-size-small;
    line-height: 1.33;
    color: $color-gray-2;
  }

  &__add-rotation-button {
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    height: 24px;
    padding: 6px 16px;
    &:hover,
    &:focus {
      color: $color-faux-black;
      outline: none;
    }

    span {
      font-size: 12px;
    }
  }

  &__container {
    overflow-y: auto;
    max-height: grid-px(60);
    padding-right: grid-px(0.5);
    padding-top: grid-px(1.5);
  }

  &__title {
    font-size: 18px;
    line-height: 1.33;
    margin-bottom: grid-px(3);
    color: $color-title-gray;
  }

  &__loader-container {
    display: flex;
    justify-content: center;
  }
}

.ad-rotation-columns {
  display: flex;
  flex-wrap: nowrap;
  gap: grid-px(1.25);
  font-size: $font-size-standard;
  width: 100%;
  overflow: hidden;

  &__column {
    display: flex;
    align-items: center;
    flex: 1;
    max-width: grid-px(21.5);
    overflow: hidden;
    color: $color-faux-black;
    white-space: nowrap;
    overflow: hidden;

    &--main {
      max-width: grid-px(56.25);
    }

    &--secondary {
      max-width: grid-px(43.75);
    }

    &__text {
      width: 100%;
      @include text-overflow-ellipsis;
    }
  }
}
