@import "../../../../../styles/colors.scss";

.alert-cell {
  display: flex;

  &__default-flag {
    > path {
      fill: $color-red-base;
    }
  }

  &__red-icon {
    svg > path {
      fill: $color-red-base;
    }

    .badge {
      margin-top: 2px;
      height: 15px;
      line-height: 1;
      background: $color-red-base;
    }

    &--clickable {
      cursor: pointer;
    }
  }
}
