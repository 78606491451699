// Z-Indices
$zero-z-index: 0;
$drawer-z-index: 300;
$header-z-index: 400;
$modal-z-index: 200;

$dropdown-container-z-index: 12;
$dropdown-menu-z-index: 5;

$editable-dropdown-container-z-index: 2;
$editable-dropdown-menu-z-index: 1;

/* 1 so that bottom border covers the TrekTabNavigation-root's bottom border */
$task-tab-selected-z-index: 1;
$alert-z-index: 1;
