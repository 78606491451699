@import '../../../../../../../styles/index.scss';
@import '../../MultipleEditDrawerContent.scss';

$multiple-edit-drawer-content-first-step-footer-weight: 400;
$multiple-edit-drawer-content-first-step-footer-total-weight: 600;
$id-column-weight: 130px;
$name-column-weight: 275px;

.multiple-edit-drawer-content-first-step {
  padding-left: $multiple-edit-drawer-content-step-left-padding;

  &__body {
    margin-top: grid-px(3);
    margin-left: grid-px(2);

    .tooltip__container {
      justify-content: flex-start; 
    }
  }

  &__footer {
    font-size: $font-size-big;
    font-weight: $multiple-edit-drawer-content-first-step-footer-weight;
    line-height: grid-px(3);
    margin-top: grid-px(7);
    margin-bottom: grid-px(15);;

    &__total {
      font-weight: $multiple-edit-drawer-content-first-step-footer-total-weight;
    }
  }

  &__cell {
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}
