@import "../../styles/index.scss";
@import ".../../../../pages/Trafficking/styles/index";

$option-padding: grid-px(1.5);

.dropdown {
  font-size: $font-size-small;
  width: 100%;
  position: relative;
  background: transparent;

  &__help-text {
    color: $color-grey-light;
    font-size: grid-px(1.5);
    line-height: grid-px(2.25);
    margin: grid-px(0.5) 0;
  }

  // this Trek wrapper div isn't labeled in any other way we can target
  div:first-child {
    cursor: pointer;
  }

  .icon-search {
    position: absolute;
    left: 8px;
    height: 20px;
  }

  &--transparent {
    background: transparent;
  }

  &--active {
    z-index: $trafficking-active-dropdown-z-index;
  }

  &--val-error button {
    border-color: $color-red-base;

    span > span {
      color: $color-red-base;
    }

    svg path {
      fill: $color-red-base !important;
    }
  }

  &__container {
    position: absolute;
    z-index: $dropdown-container-z-index;
    box-shadow: $raised-shadow-standard;
    width: 100%;
    min-width: grid-px(25);
    background-color: $white-background;
    &--not-searchable {
      .dropdown-list {
        &__control {
          // this is used to hide the searchbar behind the dropdown without `display:none`
          position: absolute;
          opacity: 0;
        }

        &__menu {
          margin-top: 0px;
        }
      }
    }
  }

  &--select-timezone {
    width: grid-px(35);
  }

  .dropdown-list {
    &__value-container {
      cursor: pointer;
      padding-left: 32px;

      :hover {
        border-color: inherit;
      }
    }

    &__control {
      margin: 8px;
      outline: none;
      box-shadow: none;
      border: 2px solid $color-gray-1;

      &--is-focused:hover {
        border-color: $color-gray-1;
      }
    }

    &__menu {
      z-index: $dropdown-menu-z-index;
      border-radius: 0;
      border-top: 1px solid $color-gray-3;
      box-shadow: $raised-shadow-standard;
      // hide top border of boxshadow
      clip-path: inset(0px -5px -5px -5px);
    }

    &__menu-list {
      padding: 0;
    }

    &__group-heading {
      margin: 0;
    }

    &__group {
      padding: 0;

      // group dividers
      &:not(:last-child) {
        border-bottom: 1px solid $color-gray-3;
      }
    }

    &__option {
      // item dividers
      display: flex;

      &:not(:first-child) {
        border-top: 1px solid $color-gray-5;
      }

      cursor: pointer;
      padding: $option-padding;
      line-height: 1.5;
      @include text-overflow-ellipsis;

      &--is-selected {
        background-color: $color-blue-6;
        color: unset;
      }

      &--is-focused:not(.dropdown-list__option--is-selected) {
        background-color: $color-gray-6;
      }

      &--is-disabled {
        cursor: not-allowed !important;
      }

      // multi-select checkbox
      .controlled-checkbox {
        display: flex;

        > input {
          width: grid-px(2);
          height: grid-px(2);
          margin: 0 grid-px() 0 0;
        }
      }
    }
  }

  &__loading,
  &__not-found {
    font-size: $font-size-small;
    line-height: 1.5;
    padding: grid-px();
    color: $color-gray-2;
    cursor: not-allowed !important;
  }

  &__not-found {
    text-align: center;
  }

  &__error {
    font-size: $font-size-small;
    line-height: 1.5;
    padding: grid-px();
    color: $color-red-base;
    cursor: not-allowed !important;
  }

  .TrekDropDown-root {
    overflow: hidden;
  }
}
