@import '../../../styles/index.scss';
@import '../Form.scss';

.color-picker {
  margin: $form-element-margin;

  &__container {
    align-items: center;
    display: flex;
    height: grid-px(5);
    justify-content: left;
  }

  &__preview-container {
    align-items: center;
    background: #f0f1f5;
    border-radius: 3px;
    border: 1px solid $border-color-dark;
    box-sizing: border-box;
    display: flex;
    flex: 0 0 grid-px(12);
    height: grid-px(5);
    justify-content: center;
  }

  &__preview {
    border-radius: 3px;
    border: 1px solid $border-color-dark;
    box-sizing: border-box;
    height: grid-px(3.5);
    margin: 0 grid-px(0.5);
    width: 100%;
  }

  &__color-format {
    padding: $default-padding;
  }

  .TrekTextField-root.TrekTextField-root {
    margin: 0;
  }

  .TrekTextField-formControl {
    width: grid-px(15);
  }

  .preview-wrapper {
    margin: 0;

    .TrekLabel-root {
      display: none;
    }

    &__content {
      // not using default in order to center correctly
      line-height: 18px;
    }
  }

  & label {
    line-height: 18px;
  }
}
