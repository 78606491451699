@import '../../styles/index.scss';

$type-width: grid-px(13);
$event-width: 66px;
$event-width-dropdown: 100px;
$url-width: grid-px(46);
$url-width-tag: 330px;

$table-cell-padding: grid-px();

$text-area-padding-vertical: grid-px() - 2;
$text-area-padding-horizontal: grid-px();

.ad-tags-table {
  border-collapse: collapse;
  table-layout: fixed;

  &__type-head {
    width: $type-width;
  }

  &__tag-head {
    width: $url-width;
  }

  &__event-head {
    width: $event-width;
  }

  tr:not(:last-of-type) {
    border-bottom: 1px solid $color-gray-5;
  }

  thead {
    tr {
      border-bottom: 1px solid $color-gray-4;
    }
  }

  tbody {
    tr:nth-last-of-type(2) {
      border-bottom: 1px solid $color-gray-3;
    }
  }

  th {
    font-size: $font-size-standard;
    font-weight: 500;
  }

  th,
  td {
    text-align: left;
    vertical-align: top;
    padding: $table-cell-padding;
    background-color: $color-white;
  }

  td.ad-tags-table__empty-msg {
    text-align: center;
    color: $color-gray-2;
    font-size: 16px;
    line-height: 24px;
  }

  &__cell-verified {
    // verified badges
    svg {
      width: 18px;
      padding: 4px 12px;
    }
  }

  .dropdown {
    &--ad-tag-type {
      width: $type-width;
    }

    &--ad-tag-event {
      width: $event-width-dropdown;

      button {
        span {
          margin-right: 1px;
        }
      }
    }

    .dropdown-button button.TrekDropDown-formControl {
      padding: 0 4px 0 12px;
      height: grid-px(4);

      > svg {
        width: 14px;
      }
    }

    .dropdown-list {
      &__menu {
        margin-top: 0;
      }

      &__menu-list {
        padding: 0;
      }
    }
  }

  textarea {
    @include auto-resizing-textarea;
    width: $url-width-tag;
    padding: $text-area-padding-vertical $text-area-padding-horizontal;
    font-size: $font-size-small;
    line-height: 1.5;

    &:focus,
    &:hover {
      padding: $text-area-padding-vertical - 1 $text-area-padding-horizontal - 1;
    }
  }
}
