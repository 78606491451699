@import '../../styles/index.scss';

.tooltip {
  opacity: 1 !important;
  padding: grid-px() !important;
  text-align: center;

  &__container {
    display: flex;
    justify-content: center;
  }
}
