@import '../../../styles/index.scss';
@import '../../AdTagsTable/AdTagsTable.scss';

$icon-width: 18px;

.ad-tags-table-rows {
  &__row {
    td.ad-tags-table-rows__empty-msg {
      text-align: center;
      color: $color-gray-2;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__cell-verified {
    // verified badges
    svg {
      width: $icon-width;
      padding: 4px 12px;
    }
  }

  &__remove-disabled-tooltip.tooltip > span {
    text-align: left;
    line-height: 1.25;
  }

  &__remove-btn.TrekIconButton-root {
    border: none;
    margin: 0;
    width: grid-px(3);
    height: grid-px(4);
    svg {
      width: $icon-width;
      @include filter-gray-2;
    }
  }

    &__verified-icon {
      display: block;
      transform: scale(0.85);
      width: 100% !important;
      height: 100%;

      &-success {
        transform: scale(0.69);
        margin-top: -3px;
      }
    }
}
