@import '../../styles/index.scss';

.navigation-select-options {
  background-color: $color-white;
  color: $color-black;

  max-height: 340px;
  min-width: 138px;
  overflow: auto;
  border-radius: grid-px(1);

  &__option {
    padding: grid-px(1) grid-px(2);
    border-bottom: $color-gray-5;
    cursor: pointer;
    text-decoration: none;
    color: $text-black;
    font-size: $font-size-standard;
    display: block;

    &:hover {
      background-color: $color-gray-6;
      color: $text-black;
    }

    &--selected {
      background-color: $color-blue-6;
    }
  }
}
