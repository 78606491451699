@import '../../../styles/index';

.weight-sum-info {
  margin: grid-px() grid-px(0.25) 0 grid-px();

  &__line {
    width: 100%;
    height: 1px;
    background-color: $color-black;
    margin-bottom: grid-px(1.75);
  }

  &__info {
    font-size: 12px;
    color: $color-gray-2;
    text-align: right;
  }
}