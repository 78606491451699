@import "../../styles/index.scss";

.trafficking-table-grouping {
    width: max-content;

    &__title-wrapper {
        border-top: grid-px(0.125) solid $color-gray-3;
        border-bottom: grid-px(0.125) solid $color-gray-3;
    }

    &__title {
        font-size: grid-px(1.75);
        font-weight: 600;
        padding: grid-px(1) grid-px(5.5);
        max-width: fit-content;
        overflow: hidden;
        position: sticky;
        top: 0;
        left: 0;
    }

    &__grouping-wrapper {
        .dropdown--active {
            z-index: 25;
        }

        .editable-cell--active {
            z-index: 25;
        }

        .toggle-section__title-content-wrapper {
            position: sticky;
            top: 0;
            left: 0;
            overflow: hidden;
        }

        .toggle-section__title-wrapper {
            background-color: $color-white;
            padding: 0;
        }

        .toggle-section__title {
            width: grid-px(80);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: no-wrap;
        }
    }
}
