@import '../../../../styles/index.scss';

.scheduling-form {
  h2 {
    font-size: grid-px(2.225);
    font-weight: normal;
    line-height: grid-px(3);
    margin-bottom: grid-px(3.5);
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
  }

  button.scheduling-form__cancel-button {
    border: none;
    color: $color-blue-1;
  }

  button.scheduling-form__confirm-buttons {
    justify-self: flex-end;
    align-self: flex-end;
    margin-left: auto;
  }

  * > .scheduling__card-inner-wrapper {
    margin: 0;
  }

  * > .datetime-input {
    margin-left: 0;
  }
}
