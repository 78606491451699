@import '../../../styles/index.scss';

.change-log-expand-collapse-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-bottom: grid-px();

  &__expand,
  &__collapse {
    color: $color-blue-base;
    cursor: pointer;
    border: none;
    background: none;
  }
}
