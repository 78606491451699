@import '../../../styles/index.scss';

$dropdown-padding-top: 9.5px;
$dropdown-padding-left: 12px;
$dropdown-padding: $dropdown-padding-top $dropdown-padding-left;

$cell-height: grid-px(6);

.editable-cell {
  .dropdown__container {
    z-index: $editable-dropdown-container-z-index;
  }

  // Dropdown appears like regular text when not open
  .dropdown-button {
    background: transparent;
    > div {
      background: transparent;
    }

    // hide the label for editable cell dropdowns
    .TrekLabel-root {
      display: none;
    }

    .TrekDropDown-root {
      .TrekDropDown-formControl {
        background: transparent;
        border: none;
        border-radius: 4px;
        > svg {
          display: none; // Hide dropdown chevron button
        }

        &:focus {
          outline: none;
        }

        .TrekDropDownItem-label {
          font-size: $font-size-standard;
        }
      }

      // Dropdown is open
      .TrekDropDown-formControlActive {
        border: 2px solid $color-faux-black;
        background: $color-white;
        padding: $dropdown-padding;

        > svg {
          display: block;
        }
      }

      // Override Trek dropdown styles
      &:hover {
        border: none;
      }
    }
  }

  .dropdown-list {
    &__menu {
      margin: -4px 0 0 0;
      z-index: $editable-dropdown-menu-z-index;
    }

    &__menu-list {
      padding: 0;
    }

    &__option {
      padding: 13.5px grid-px(3); // hard-coded top/bottom padding pulled from Trek
      font-size: $font-size-standard;
    }

    &__control {
      line-height: 20px;
    }
  }

  // Show dropdown chevron when cell is in hover state
  &:hover,
  &:focus {
    .TrekDropDown-root {
      .TrekDropDown-formControl {
        > svg {
          display: block;
        }
      }
    }
  }

  &--saving {
    // Dropdown appears like regular text when not open
    .dropdown-button {
      .TrekDropDown-root {
        .TrekDropDown-formControl {
          background: transparent;
        }

        // Text
        > span {
          color: $color-gray-3;
        }

        &:hover {
          cursor: progress;
        }
      }
    }
    &:hover {
      // Dropdown appears like regular text when not open
      .dropdown-button {
        .TrekDropDown-root {
          .TrekDropDown-formControl {
            > svg {
              display: none;
            }
          }
        }
      }
    }
  }

  & .is-searchable {
    .dropdown-list {
      &__menu {
        margin: 8px 0 0 0;
      }
    }
  }
}
