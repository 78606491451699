@import '../../../../../../../styles/index.scss';

.multiple-edit-confirm-error {
  display: flex;
  margin-top: grid-px(2);

  &__wrapper-error-text {
    white-space: pre-line;
    display: flex;
    flex-direction: column;
    color: $color-red-input-error;
  }

  &__error-icon {
    margin-right: grid-px(2);
  }
}
