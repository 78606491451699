@import '../../../styles/index.scss';

.targeting {
  & .targeting-dropdowns {
    padding: grid-px(3) grid-px(2);
  }

  & .card__content {
    padding: grid-px();
  }

  &__publisher {
    font-weight: 600;
    padding-left: 16px;
    margin-top: 10px;
    margin-bottom: 0;
  }

  &__country {
    font-weight: 600;
    padding-left: 16px;
    margin-top: 10px;
    margin-bottom: 0;
  }
}