@import '../../../../../../../styles/index.scss';

.multi-edit-schedule {
  &__date-title {
    display: flex;
    margin-bottom: grid-px();
    align-items: center;

    h5 {
      margin: 0;
      color: $color-gray-2;
      font-size: 18px;
    }

    svg {
      margin-right: grid-px();

      path {
        fill: $color-gray-2;
      }
    }
  }

  &__subtitle {
    font-size: $font-size-small;
    line-height: 1.3;
    color: $color-gray-1;
    margin: grid-px(0.5) 0 grid-px();
  }

  .daypart-tabs {
    margin-top: grid-px(2);
  }
}
