@import "../../../styles/index.scss";

.unsequenced-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__title {
        font-weight: 600;
    }

    .TrekSearchInput-root {
        background-color: $color-white;
        height: grid-px(4);
    }

    &__search {
        &--active {
            .TrekSearchInput-root {
                border: 1px solid $color-green-4;
                background-color: $color-green-6;
            }
        }
    }
}
