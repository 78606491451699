@import '../../../styles/index.scss';

.editable-cell-modal {
  &__wrapper {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    // set to 0 so that we do not catch clicks outside of the modal content
    // and buttons, where the space _appears_ empty, but is not
    height: 0;
    position: absolute;
    z-index: $modal-z-index;
  }

  &__buttons {
    display: flex;
    margin-top: 4px;
    justify-content: flex-end;

    button {
      border-radius: $border-radius-standard;
      border: 1px solid $border-color-light;
      box-shadow: $raised-shadow-standard;
      box-sizing: border-box;
      height: grid-px(5);
      margin-left: grid-px(0.5);
      width: grid-px(5);

      &:not(:focus) {
        outline: none;
      }

      &:active {
        // when clicked, button should look like it's pressed
        transform: translateY($shadow-height-standard);
        box-shadow: none;
      }

      img {
        vertical-align: middle;
      }
    }
  }

  &__cancel-btn {
    background: $color-white;
  }

  &__submit-btn {
    background: black;
    svg {
      @include filter-white;
    }
  }

  &__tooltip {
    display: flex;
    align-items: center;
    opacity: 1 !important;
    max-height: grid-px(4.275);
  }

  // custom Trek Alert styling
  .TrekAlert-root {
    height: grid-px(5);
    padding: 0;

    .TrekAlertHeader-root {
      height: grid-px(5);
    }

    .TrekAlertHeader-close {
      display: none;
    }

    .TrekAlertHeader-title {
      display: none;
      margin: 0 grid-px(0.5);
    }

    .TrekAlertHeader-icon {
      align-self: center;
      height: grid-px(2);
    }

    .TrekAlertHeader-content {
      display: flex;
      align-items: center;
    }

    .TrekAlertHeader-CTA {
      font-size: 12px;
    }
  }
}
