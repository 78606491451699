@import '../../styles/index.scss';

.save-input {
  height: grid-px(4);
  display: block;
  position: relative;
  width: 100%;

  &__input {
    height: grid-px(4);
    padding-left: grid-px(1);
    border: 1px solid $border-color-light;
    border-radius: 4px;
    box-sizing: border-box;
    width: 100%;
    font-size: 12px;
    outline: none;

    &:focus, &:focus-visible {
      border: 1px solid $border-color-light;
    }
  }

  &__input-button {
    cursor: pointer;
    width: grid-px(5);
    height: grid-px(4);
    border-radius: 4px;
    background-color: $color-faux-black;
    position: absolute;
    right: 0;
    top: 0;
    border: none;

    span {
      * {
        fill: $color-white;
      }
    }

    &--disabled {
      cursor: default;
      background-color: $color-gray-4;
    }
  }
}
