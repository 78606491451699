@import "../../../styles/index.scss";

.sequenced-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &__name-cell {
        display: flex;
        font-weight: 600;
    }

    &__delete-sequence-cell {
        cursor: pointer;
        height: grid-px(3);

        svg path {
            fill: $color-gray-2;
        }

        &:hover {
            svg path {
                fill: $color-faux-black;
            }
        }
    }
}
