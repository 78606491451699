@import '../../../styles/index';

.targeting-table-action-cell {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  > div {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
  }

  &__button {
    @include button-reset;

    svg {
      @include filter-gray-2;
      vertical-align: middle;
      width: 21px;
    }
  }

  .toggle-switch {
    &__toggler {
      padding: 0;
    }
  }

  .targeting-table-action-cell__action-icons {
    min-width: 25px;

    .targeting-table-action-cell__modal-trigger-icon  {
        @include filter-blue-1;
    }
  }
}
