@import '../../styles/index.scss';

.basic-select {
  &__tooltip {
    opacity: 1 !important;
    padding: 0 !important;
  }
}

.rc-tooltip {
  top: -1000px;
  left: -1000px;
}

.rc-tooltip-arrow {
  display: none;
}

.rc-tooltip-inner {
  padding: 0 !important;
}
