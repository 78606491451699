@import '../../styles/index.scss';

.modal-popup {
  //Ant Modal related styles
  .ant-modal-title {
    min-width: 500px;
    height: 30px;
    font-size: 22px;
    font-weight: 400;
  }

  .ant-modal-header {
    border-bottom: transparent;
    padding: 24px;
  }

  .ant-modal-content {
    min-width: 550px;
  }

  .ant-modal-body {
    padding-top: 0;
    min-width: 500px;
    height: fit-content;
    left: 44px;
    top: 104px;
    font-size: 16px;
    line-height: 24px;
  }

  .ant-modal-close {
    outline: revert;
  }

  .ant-modal-footer {
    border-top: transparent;

    .ant-btn {
      background: transparent;
      border: transparent;
      position: static;
      padding: 12px 24px;
      font-size: $font-size-standard;
      font-weight: bold;
      height: 36px;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: $color-blue-1;
      flex: none;
      align-items: center;
      order: 0;
      flex-grow: 0;
      margin: 12px 12px;
      display: inline-flex;

      :hover {
        cursor: pointer;
      }

      @include keyboard-focus-only;
    }

    .ant-btn.ant-btn-primary {
      right: 0;
      bottom: 0;
      background: $color-faux-black;
      border-radius: 4px;
      flex: none;
      order: 1;
      flex-grow: 0;
      color: white;
      margin: grid-px(1.5);
    }
  }
}