@import '../../../styles/index.scss';
@import '../../../common/Form/Form.scss';

.ad-form {
  flex: 1;
  display: flex;
  flex-direction: column;

  &__content {
    padding: grid-px(1.5) 0 0;
    overflow-y: auto;
    height: calc(#{$ads-content-height} - #{$ads-header-height});

    > div {
      margin: grid-px(4);
    }

    > div:first-child {
      margin-top: 0;
    }
  }

  // Styles for all form sections
  .title {
    font-size: 18px;
    line-height: 24px;
  }

  .card {
    margin-top: 12px;
    width: grid-px(89);
    &__content {
      margin: 0 auto;

      .text-field-container {
        width: grid-px(35);
      }
      .creative-builder {
        .TrekTextField-root {
          margin: 0;
        }
      }
      .TrekTextField-root {
        margin: grid-px(4) grid-px(3);
      }
    }
  }
}
