@import '../../styles/colors.scss';
@import '../../styles/constants.scss';

.ant-notification.ant-notification-bottomRight {
  margin-right: 40px;
}

.ant-notification {
  &-notice {
    padding: grid-px(3);

    &-icon {
      margin: 0;
    }

    &-close {
      top: grid-px(3);
      cursor: pointer;
    }

    &-message {
      font-size: $font-size-standard;
      color: $color-faux-black;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    &-with-icon &-message {
      margin-bottom: 0;
    }

    &-description strong {
      font-weight: 600;
    }
  }
}
