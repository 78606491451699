@import "../../styles/index";

.icon-title {
  display: flex;
  align-items: center;
  font-weight: 500;
  color: $color-gray-2;

  svg path {
    fill: $color-gray-2;
  }

  &__text {
    margin-left: grid-px();
  }
}
