@import '../../styles/index.scss';

.times-list {
  display: flex;
  border: 1px solid $color-blue-base;
  width: grid-px(35);
  padding: grid-px(1.75);
  background-color: $color-blue-6;

  &__icon-wrapper {
    width: grid-px(4.375);
  }

  svg {
    width: grid-px(3);
    height: grid-px(3);

    path {
      fill: $color-blue-base;
    }
  }

  &__content {
    width: 100%;

    &--title {
      display: flex;
      align-items: center;
      height: grid-px(3);
      font-size: 12px;
      font-weight: bold;
      color: $color-faux-black;
    }
  }

  .time-items {
    display: grid;
    align-items: center;
    grid-template-columns: 30px 1fr;
    grid-column-gap: 9px;
    padding-top: grid-px(0.5);

    &__day {
      font-size: 12px;
      text-align: right;
      color: $color-gray-2;
      font-weight: 600;
    }

    &__hours {
      font-size: 12px;
      line-height: 18px;
      color: $color-faux-black;
    }
  }
}
